export const ROUTE_BASE = '/accruentcentral';

export const ROUTE_ADMIN = `${ROUTE_BASE}/admin`;

export const ROUTE_ERROR = `${ROUTE_BASE}/error`;

export const ROUTE_LOGGED_OUT = `${ROUTE_BASE}/loggedout`;

export const ROUTE_LOGIN = `${ROUTE_BASE}/login`;

export const ROUTE_IDP_TEST = '/SAML/TestIdentityProvider';

export const ROUTE_IDP_TEST_RESULTS = `${ROUTE_BASE}/SAMLTestResults`;

export const ROUTE_WELL_KNOWN_CONFIGS = '/.well-known/openid-configuration';

export default ROUTE_BASE;
