import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { injectIntl, intlShape } from 'react-intl';
import { Header } from '@accruent/acc-react-components';
import { deleteCookie } from '../../../Utilities/accountUtilities';
import * as Constants from '../../../../constants';
import './ApplicationHeader.scss';
import { isDevEnvironment } from '../../../Utilities/environmentUtilities';

class ApplicationHeader extends Component {
  logOut = () => {
    if (isDevEnvironment) {
      deleteCookie('idsrv.session');
    }
    window.location.href = Constants.LOGOUT_ROUTE;
  };

  getUserMenuItems = () => {
    const { intl, userDetails } = this.props;
    const adminLinkLabel = { id: 'Menu.Admin', defaultMessage: 'Admin' };
    const signOutLabel = { id: 'Menu.SignOut', defaultMessage: 'Sign Out' };

    const userRole = Constants.DATA_ADMIN_LEVELS[userDetails.dataAdministrationLevel];
    const adminAccess = userRole && userRole.permissions.includes(Constants.PERMISSIONS.viewAdmin);

    return [
      ...(adminAccess
        ? [
          {
            label: intl.formatMessage(adminLinkLabel),
            action: this.navigateToAdminPage,
          },
        ]
        : []),
      {
        label: intl.formatMessage(signOutLabel),
        action: this.logOut,
      },
    ];
  };

  navigateToAdminPage = () => {
    window.location.href = Constants.ROUTE_ADMIN;
  };

  render() {
    const {
      intl,
      appTitle,
      breakpoint,
      breakpointMedium,
      userDetails,
      loading,
      onLogoClick,
      logo,
    } = this.props;
    const defaultTitle = { id: 'App.Title', defaultMessage: 'Accruent Central' };

    return (
      <Header
        id="base-header"
        loading={loading}
        onLogoClick={onLogoClick}
        appTitle={appTitle || intl.formatMessage(defaultTitle)}
        userName={userDetails && userDetails.fullName}
        userTitle={userDetails && userDetails.role}
        userMenuItems={this.getUserMenuItems()}
        breakpoint={breakpoint}
        breakpointMedium={breakpointMedium}
        helpIconVisible={false}
        logo={logo}
      />
    );
  }
}

ApplicationHeader.propTypes = {
  intl: intlShape.isRequired,
  appTitle: PropTypes.string,
  breakpoint: PropTypes.number,
  breakpointMedium: PropTypes.number,
  userDetails: PropTypes.shape({
    fullName: PropTypes.string,
    userName: PropTypes.string,
    role: PropTypes.string,
    dataAdministrationLevel: PropTypes.string,
  }),
  loading: PropTypes.bool,
  logo: PropTypes.element,
  onLogoClick: PropTypes.func,
};

ApplicationHeader.defaultProps = {
  appTitle: undefined,
  breakpoint: 768,
  breakpointMedium: 1024,
  loading: false,
  logo: null,
  userDetails: {},
  onLogoClick: undefined,
};

export default injectIntl(ApplicationHeader);
