import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { injectIntl, intlShape } from 'react-intl';
import { Footer } from '@accruent/acc-react-components';

class ApplicationFooter extends Component {
  render() {
    const { intl, breakpoint } = this.props;
    const privacyMessage = { id: 'Footer.PrivacyPolicyLabel', defaultMessage: 'Privacy Policy' };
    const termsMessage = { id: 'Footer.TermsOfServiceLabel', defaultMessage: 'Terms of Use' };
    const legalMessage = { id: 'Footer.LegalLabel', defaultMessage: 'Legal' };
    return (
      <Footer
        id="base-footer"
        breakpoint={breakpoint}
        privacyLabel={intl.formatMessage(privacyMessage)}
        privacyLinkVisible
        termsLabel={intl.formatMessage(termsMessage)}
        termsLinkVisible
        legalLabel={intl.formatMessage(legalMessage)}
        legalLinkVisible
        socialLinksVisible
      />
    );
  }
}

ApplicationFooter.propTypes = {
  intl: intlShape.isRequired,
  breakpoint: PropTypes.number,
};

ApplicationFooter.defaultProps = {
  breakpoint: 768,
};

export default injectIntl(ApplicationFooter);
