import React, { Component } from 'react';
import { PropTypes } from 'prop-types';
import ProductIcon from '../../Atoms/ProductIcon/ProductIcon';
import GenericIcon from '../../Atoms/GenericIcon/GenericIcon';
import { GenericIconCodes } from '../../../../constants/configurationConstants';
import './ProductIconSelection.scss';

export default class ProductIconSelection extends Component {
  constructor(props) {
    super();

    this.icons = [
      <ProductIcon key={props.signInUrl} url={props.signInUrl} iconCode={null} />,
      <GenericIcon key={GenericIconCodes.lease} iconCode={GenericIconCodes.lease} />,
      <GenericIcon key={GenericIconCodes.project} iconCode={GenericIconCodes.project} />,
      <GenericIcon key={GenericIconCodes.workOrder} iconCode={GenericIconCodes.workOrder} />,
      <GenericIcon key={GenericIconCodes.assetManagement} iconCode={GenericIconCodes.assetManagement} />,
      <GenericIcon key={GenericIconCodes.capitalPlanning} iconCode={GenericIconCodes.capitalPlanning} />,
      <GenericIcon key={GenericIconCodes.documentsManager} iconCode={GenericIconCodes.documentsManager} />,
    ];
  }

  onKeyUp = (event, iconCode) => {
    const { onIconSelected } = this.props;

    if (event.keyCode === 13) {
      onIconSelected(iconCode);
    }
  };

  render() {
    const { onIconSelected, selectedIconKey } = this.props;

    return (
      <div className="product-button-icon-container">
        {this.icons.map(icon => {
          // eslint-disable-next-line eqeqeq
          const selectedClass = icon.props.iconCode == selectedIconKey ? 'product-button-icon-selected' : '';

          return (
            <div
              key={icon.props.iconCode}
              className={`product-button-icon-selection ${selectedClass}`}
              role="button"
              onClick={() => onIconSelected(icon.props.iconCode)}
              tabIndex={0}
              onKeyUp={event => this.onKeyUp(event, icon.props.iconCode)}
            >
              {icon}
            </div>
          );
        })}
      </div>
    );
  }
}

ProductIconSelection.propTypes = {
  onIconSelected: PropTypes.func.isRequired,
  selectedIconKey: PropTypes.string,
  signInUrl: PropTypes.string,
};

ProductIconSelection.defaultProps = {
  selectedIconKey: undefined,
  signInUrl: undefined,
};
