import React, { Component } from 'react';
import './AppGreeting.scss';
import { PropTypes } from 'prop-types';
import { FormattedMessage, injectIntl, intlShape } from 'react-intl';
import { AdaptiveResponse } from '@accruent/acc-react-components';

export function getGreetingMessage(hours) {
  const morningGreeting = { id: 'Greeting.GoodMorning', defaultMessage: 'Good morning' };
  const afternoonGreeting = {
    id: 'Greeting.GoodAfternoon',
    defaultMessage: 'Good afternoon',
  };
  const eveningGreeting = { id: 'Greeting.GoodEvening', defaultMessage: 'Good evening' };
  const defaultGreeting = { id: 'Greeting.Greetings', defaultMessage: 'Greetings' };

  switch (true) {
    case hours >= 0 && hours < 12:
      return morningGreeting;
    case hours >= 12 && hours < 17:
      return afternoonGreeting;
    case hours >= 17 && hours <= 24:
      return eveningGreeting;
    default:
      return defaultGreeting;
  }
}

class AppGreeting extends Component {
  renderMessages = (className = '') => {
    const { intl, userFirstName, currentHour } = this.props;
    const greetingMessage = getGreetingMessage(currentHour);
    const greeting = intl.formatMessage(greetingMessage);
    const punctuation = userFirstName ? ', ' : '.';
    return (
      <div className="message-container">
        <span className={className}>
          <span className={`message ${className}`}>{`${greeting}${punctuation}`}</span>
          {userFirstName ? (
            <span className={`message ${className}`}>{`${userFirstName}.`}</span>
          ) : (
            ''
          )}
        </span>
        <div className={`welcome ${className}`}>
          <FormattedMessage
            id="Greeting.WelcomeToAccruent"
            defaultMessage="Welcome to Accruent Central."
          />
        </div>
      </div>
    );
  };

  render() {
    const { breakpoint } = this.props;

    return (
      <AdaptiveResponse
        breakpoint={breakpoint}
        smallScreen={this.renderMessages('compact')}
        largeScreen={this.renderMessages()}
      />
    );
  }
}

AppGreeting.propTypes = {
  intl: intlShape.isRequired,
  userFirstName: PropTypes.string,
  breakpoint: PropTypes.number,
  currentHour: PropTypes.number,
};

AppGreeting.defaultProps = {
  userFirstName: undefined,
  breakpoint: 768,
  currentHour: new Date().getHours(),
};

export default injectIntl(AppGreeting);
