import { isDevEnvironment } from '../App/Utilities/environmentUtilities';

export const API = isDevEnvironment ? 'http://localhost:3001/' : '/';

// To test against the feature branch api, use the API below instead (for dev testing).
// export const API = isDevEnvironment ? 'https://centralauth-feature.accruent.com/' : '/';
// export const API = isDevEnvironment
//   ? 'https://authsvc-developer-testing.azurewebsites.net/'
//   : '/';

export const API_RESOURCES_ROUTE = `${API}api/internal/apiresources`;

export const CLIENTS_ROUTE = `${API}api/internal/clients`;

export const LOGIN_ROUTE = `${API}account/login`;

export const LOGIN_EXTERNAL_ROUTE = `${API}external/login`;

export const LOGIN_VIEW_MODEL_ROUTE = `${API}account/getloginviewmodel`;

export const LOGOUT_ROUTE = `${API}connect/endsession`;

export const METRICS_ROUTE = `${API}metrics`;

export const TENANT_ROUTE = `${API}api/gettenantbyalias`;

export const TENANT_IDENTITY_PROVIDERS_ROUTE = `${API}api/internal/clientidentityproviders`;

export const TENANTS_ROUTE = `${API}api/internal/tenants`;

export const USER_DETAILS_ROUTE = `${API}api/LoggedInUserDetails`;

export const USERS_ROUTE = `${API}api/internal/users`;

export const USERS_EXTERNAL_ROUTE = `${API}api/external/users`;

export const COMMON_CLAIMS = `${API}api/internal/commonclaims`;

export default API;
