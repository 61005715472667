import React from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import './FontAwesomeButton.scss';

export default class FontAwesomeButton extends React.Component {
    handleKeyUp = event => {
      const { onClick } = this.props;

      if (event.keyCode === 13 && typeof onClick === 'function') {
        onClick(event);
      }
    };

    render() {
      const {
        icon, id, onClick, role, tabIndex,
      } = this.props;

      // Don't wire up click or focus handlers if onClick is not passed in
      const actionProps = onClick && {
        onClick,
        onKeyUp: this.handleKeyUp,
        role,
        tabIndex,
      };

      return (
        <span
          {...actionProps}
          className="font-awesome-button"
          id={id}
          aria-label={id}
        >
          <FontAwesomeIcon icon={icon} />
        </span>
      );
    }
}

FontAwesomeButton.defaultProps = {
  onClick: undefined,
  role: 'button',
  tabIndex: '0',
};

FontAwesomeButton.propTypes = {
  /** Valid FontAwesome icon */
  icon: PropTypes.shape({
    prefix: PropTypes.string,
    iconName: PropTypes.string,
    icon: PropTypes.arrayOf(PropTypes.any),
  }).isRequired,

  /** Html id for the icon */
  id: PropTypes.string.isRequired,

  /** Action to be fired when icon is clicked */
  onClick: PropTypes.func,

  /** Aria Role type */
  role: PropTypes.string,

  /** Tab index */
  tabIndex: PropTypes.string,
};
