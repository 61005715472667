export const SUPPORT_EMAIL = 'support@accruent.com';

export const FABRIC_TRIAGE_EMAIL = '7ef04326.accruent.com@amer.teams.ms';

export const FABRIC_TEAM_EMAIL = 'FabricIntegrationTeam@accruent.com';

export const PERMISSIONS = {
  viewAdmin: 'viewAdmin',
  editProduct: 'editProduct',
  createProduct: 'createProduct',
  createTenant: 'createTenant',
  deleteTenant: 'deleteTenant',
};

export const DATA_ADMIN_LEVELS = {
  SystemAdministrator: {
    permissions: [
      PERMISSIONS.viewAdmin,
      PERMISSIONS.editProduct,
      PERMISSIONS.createProduct,
      PERMISSIONS.createTenant,
      PERMISSIONS.deleteTenant,
    ],
  },
  ProductAdministrator: {
    permissions: [
      PERMISSIONS.viewAdmin,
      PERMISSIONS.editProduct,
      PERMISSIONS.createProduct,
      PERMISSIONS.createTenant,
      PERMISSIONS.deleteTenant,
    ],
  },
  ApplicationAdministrator: { permissions: [PERMISSIONS.viewAdmin] },
};

export const ADD_CLIENT_CONFIG_LINK = 'https://accruent.atlassian.net/wiki/spaces/PP/pages/778240887/Add+new+entry+in+Clients+Configuration';

export const SAML_METADATA_LINK = 'https://centralauth.accruent.com/Saml/Metadata';

export const IDP_SETUP_LINK = 'https://accruent.atlassian.net/wiki/spaces/PP/pages/1048970357/ACCRUENT+CENTRAL+AUTH+CUSTOMER+COMMUNICATION';

export default SUPPORT_EMAIL;
