import React, { Component } from 'react';
import { PropTypes } from 'prop-types';
import queryString from 'query-string';
import { Button, CheckboxOption, AccFontAwesomeIcon } from '@accruent/acc-react-components';
import './SystemAlert.scss';

export default class SystemAlert extends Component {
  hasAcknowledgement = true;

  constructor() {
    super();
    this.state = {
      checked: false,
      hidden: false,
      showRequired: false,
      alertHeight: 'auto',
      messageHeight: 'auto',
    };

    this.alertElement = { clientHeight: 0 };
    this.messageElement = { clientHeight: 0 };
  }

  componentDidMount() {
    const { setAcknowledgementNeeded } = this.props;
    const queryValues = queryString.parse(window.location.search) || {};

    setAcknowledgementNeeded(this.hasAcknowledgement && queryValues.showAlert === 'true');

    this.updateDimensions();
  }

  toggleChecked = () => {
    const { checked } = this.state;
    const { setAcknowledgementNeeded } = this.props;

    setAcknowledgementNeeded(this.hasAcknowledgement && checked);

    const alertHeight = `${this.alertElement.clientHeight}px`;
    this.setState({ checked: !checked, alertHeight });
  };

  closeButtonClick = () => {
    const { checked } = this.state;

    if (!this.hasAcknowledgement || checked) {
      this.setState({ hidden: true });
    } else if (this.hasAcknowledgement && !checked) {
      this.setState({ showRequired: true, alertHeight: 'auto' });
    }
  };

  updateDimensions = () => {
    const alertHeight = `${this.alertElement.clientHeight}px`;
    const messageHeight = `${this.messageElement.clientHeight}px`;
    this.setState({ alertHeight, messageHeight });
  };

  render() {
    const {
      alertHeight, checked, hidden, messageHeight, showRequired,
    } = this.state;
    const alertTitle = 'This is a System Message Title';
    const alertMessage = 'This is intended to inform the user that something may have changed in Accruent Central - the system will be down for maintenance, updates to the product, etc. While this box had another look for a more casual announcement like an event or social message, we wanted to create a distinct difference so the user wouldn’t ignore an important message while assuming it was more casual in nature like the other design. Here is the link style. Check box & Buttons below can be removed if not needed. This is intended to inform the user that something may have changed in Accruent Central.';
    const acknowledgementMessage = 'Can be used if acknowledgement is required';
    const acknowledgementRequired = 'You must acknowledge that you have read the above message to log in to Accruent Central.';
    const closeButtonTitle = 'Optional Button';

    const hiddenClass = hidden ? 'hidden' : '';
    const requiredHiddenClass = showRequired ? '' : 'hidden';

    const alertStyle = alertHeight === 'auto' ? {} : { maxHeight: alertHeight };
    const messageStyle = messageHeight === 'auto' ? {} : { maxHeight: messageHeight };

    const queryValues = queryString.parse(window.location.search) || {};

    return queryValues.showAlert === 'true' ? (
      <div
        id="system-alert"
        className={`system-alert ${hiddenClass}`}
        aria-hidden={hidden}
        ref={alertElement => {
          this.alertElement = alertElement;
        }}
        style={alertStyle}
      >
        <div id="system-alert-content" className="system-alert-content">
          <AccFontAwesomeIcon
            id="system-alert-content-icon"
            iconCode="exclamation-triangle"
            className="system-alert-icon"
            role="presentation"
          />
          <div id="system-alert-title" className="system-alert-title">
            {alertTitle}
          </div>
          <div
            id="system-alert-message"
            className="system-alert-message"
            ref={messageElement => {
              this.messageElement = messageElement;
            }}
            style={messageStyle}
          >
            {alertMessage}
          </div>
          {this.hasAcknowledgement && (
            <div>
              <CheckboxOption
                id="system-alert-checkbox"
                label={acknowledgementMessage}
                name="system-alert-checkbox"
                checked={checked}
                onChange={this.toggleChecked}
                size="large"
                value=""
              />
              {closeButtonTitle && (
                <div
                  id="system-alert-required"
                  className={`system-alert-required ${requiredHiddenClass}`}
                >
                  {acknowledgementRequired}
                </div>
              )}
            </div>
          )}
          {closeButtonTitle && (
            <Button ariaLabel={closeButtonTitle} onClick={this.closeButtonClick}>
              {closeButtonTitle}
            </Button>
          )}
        </div>
        <span className="collapse-icon-span" />
      </div>
    ) : null;
  }
}

SystemAlert.propTypes = {
  setAcknowledgementNeeded: PropTypes.func,
};

SystemAlert.defaultProps = {
  setAcknowledgementNeeded: () => {},
};
