import axios from 'axios';
import util from 'util';
import { openToast } from '@accruent/acc-react-components';
import ErrorMessage from '../Components/Molecules/ErrorMessage/ErrorMessage';
import * as Console from './Console';

export function displayNetworkError(error) {
  if (!axios.isCancel(error)) {
    openToast({
      type: 'error',
      centerNode: ErrorMessage({ id: 'base-error-mesage' }),
      rightLinkCallback: () => {}, // Leaves the error toast open.
    });
  }

  if (error) {
    Console.log('API Error: ', error.message);
  }
}

export function combineErrorMessages(error) {
  let errorMessages = 'Unknown API error';
  Console.log(`API Error: ${util.inspect(error)}`);

  if (axios.isCancel(error)) {
    return null;
  }

  if (error && error.response) {
    if (error.response.data && error.response.data.Errors) {
      try {
        errorMessages = error.response.data.Errors.join(' ');
      } catch {
        errorMessages = 'Unable to parse API Error message';
      }
    } else if (error.response.statusText) {
      errorMessages = `API error for endpoint ${error.config.url}: `
        + `${error.response.status} - ${error.response.statusText}`;
    }
  }
  return errorMessages;
}

export default displayNetworkError;
