import './styles/css/index.css';
import './styles/scss/index.scss';
import 'core-js'; // Used for IE 11 compatibility
import React from 'react';
import ReactDOM from 'react-dom';

import { IntlProvider, addLocaleData } from 'react-intl';
import localeEn from 'react-intl/locale-data/en';
import localeEs from 'react-intl/locale-data/es';
import localeDe from 'react-intl/locale-data/de';
import localeEo from 'react-intl/locale-data/eo';

import App from './App/App';
import * as serviceWorker from './App/serviceWorker';
import getTranslatedMessages from './App/Utilities/languageUtilities';

const ElementQueries = require('css-element-queries/src/ElementQueries');
// attaches to DOMLoadContent and detects new DOM entries
ElementQueries.listen();

addLocaleData([...localeEn, ...localeEs, ...localeDe, ...localeEo]);

const translationInfo = getTranslatedMessages();

ReactDOM.render(
  <IntlProvider
    locale={translationInfo.language}
    messages={translationInfo.translatedMessages}
  >
    <App />
  </IntlProvider>,
  document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
