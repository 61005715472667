import React, { Component } from 'react';
import { PropTypes } from 'prop-types';
import { injectIntl, intlShape } from 'react-intl';
import axios from 'axios';
import { Button, Input } from '@accruent/acc-react-components';
import { getTenantByAlias } from '../../../../api/apiCalls';
import { displayNetworkError } from '../../../Utilities/networkUtilities';
import FormError from '../../Atoms/FormError/FormError';
import * as Constants from '../../../../constants';
import './CompanyFinder.scss';

class CompanyFinder extends Component {
  constructor() {
    super();
    this.state = { companyField: '', notFound: false };
  }

  componentWillUnmount() {
    if (this.signal) {
      this.signal.cancel(Constants.CANCELED_UNMOUNTING);
    }
  }

  onCompanyChange = event => {
    this.setState({ companyField: event.target.value });
    this.setState({ notFound: false });
  };

  findTenant = async () => {
    const { onTenantFound } = this.props;
    const { companyField } = this.state;

    // Cancel the previous request.
    if (this.signal) {
      this.signal.cancel(Constants.CANCELED_NEW_REQUEST);
    }

    // Save the new request for cancellation.
    this.signal = axios.CancelToken.source();

    try {
      const data = await getTenantByAlias(this.signal.token, companyField);

      if (data === {} || data === '') {
        this.setState({ notFound: true });
      } else if (data && typeof onTenantFound === 'function') {
        onTenantFound({ tenantUuid: data.uuid });
      }
    } catch (error) {
      displayNetworkError(error);
    }
  };

  render() {
    const { companyField, notFound } = this.state;
    const { intl } = this.props;
    const companyPlaceholder = {
      id: 'Generic.Company',
      defaultMessage: 'Company',
    };
    const continueMessage = {
      id: 'Generic.Continue',
      defaultMessage: 'Continue',
    };
    const companyNotFound = {
      id: 'Error.CompanyNotFound',
      defaultMessage:
        "Sorry, we couldn't find the company that was entered. Please try again or contact your administrator.",
    };
    return (
      <h1 id="company-finder" className="company-finder">
        <Input
          ariaLabel={intl.formatMessage(companyPlaceholder)}
          className="company-finder-field"
          hasError={notFound}
          id="company-input"
          maxLength={100}
          onChange={this.onCompanyChange}
          onEnterKey={this.findTenant}
          placeholder={intl.formatMessage(companyPlaceholder)}
          value={companyField}
        />
        <FormError id="company-error" hidden={!notFound}>
          {intl.formatMessage(companyNotFound)}
        </FormError>
        <Button id="find-company-button" onClick={this.findTenant} fullWidth>
          {intl.formatMessage(continueMessage)}
        </Button>
      </h1>
    );
  }
}

CompanyFinder.propTypes = {
  intl: intlShape.isRequired,
  onTenantFound: PropTypes.func,
};

CompanyFinder.defaultProps = {
  onTenantFound: undefined,
};

export default injectIntl(CompanyFinder);
