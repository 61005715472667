import React, { Component } from 'react';
import { PropTypes } from 'prop-types';
import { FormattedMessage, injectIntl, intlShape } from 'react-intl';
import {
  AutoCompleteDropdown, Button, Label, LoadingIcon, Modal, openToast,
} from '@accruent/acc-react-components';
import * as Constants from '../../../../constants';
import ErrorField from '../../Atoms/ErrorField/ErrorField';
import './ProductButtonAddModal.scss';

class ProductButtonAddModal extends Component {
  constructor() {
    super();
    this.state = {
      saving: false,
      addProductValue: undefined,
      errorMessage: undefined,
    };
  }

  navigateToUrl = redirectUrl => {
    if (redirectUrl) {
      window.open(redirectUrl, '_blank');
    } else {
      openToast({
        type: 'error',
        centerNode: <FormattedMessage
          id="ProductSelection.UrlNotFound"
          defaultMessage="We did not find a configured URL for this product, please reach out to your administrator."
        />,
      });
    }
  };

  onAddProduct = async () => {
    const { addProductValue } = this.state;
    const { intl, onProductsChanged, products } = this.props;

    const dropdownOptions = products
      .filter(product => product.status === Constants.PRODUCT_STATUSES.NotAdded)
      .map(product => ({
        value: product.clientId,
        label: product.displayName || product.clientName,
      }));
    const dropdownValue = dropdownOptions.length === 1 ? dropdownOptions[0] : addProductValue;

    if (!dropdownValue) {
      const errorMessage = intl.formatMessage({
        id: 'ProductSelection.ProductRequired',
        defaultMessage: 'Please select a product.',
      });

      this.setState({ errorMessage });
      return;
    }

    const updatedProducts = JSON.parse(JSON.stringify(products));
    const addedProductIndex = products.findIndex(product => product.clientId === dropdownValue.value);
    updatedProducts[addedProductIndex].status = Constants.PRODUCT_STATUSES.ManuallyAddedVerified;
    const savingTimeout = setTimeout(() => this.setState({ saving: true }), Constants.LoadingIconDelayMilliseconds);

    const errorMessage = await onProductsChanged(updatedProducts);

    clearTimeout(savingTimeout);
    this.setState({ saving: false });

    if (errorMessage) {
      this.setState({ errorMessage });
    } else {
      this.onClose();
      this.navigateToUrl(updatedProducts[addedProductIndex].signInUrl);
    }
  };

  onClose = () => {
    const { onModalClose } = this.props;

    this.setState({
      errorMessage: undefined,
      addProductValue: undefined,
    });
    onModalClose();
  };

  handleDropdownChange = selectedValue => this.setState({ addProductValue: selectedValue });

  renderErrorField = () => {
    const { errorMessage } = this.state;
    const { intl } = this.props;

    const errorTitle = intl.formatMessage({
      id: 'ProductSelection.ErrorSavingSettings',
      defaultMessage: 'An error has occurred attempting to save these user settings.',
    });

    return (
      <ErrorField title={errorTitle}>
        {errorMessage}
      </ErrorField>
    );
  };

  render() {
    const { addProductValue, saving } = this.state;
    const { intl, isOpen, products } = this.props;

    const chooseProductLabel = intl.formatMessage({
      id: 'ProductSelection.ChooseProduct',
      defaultMessage: 'Choose Product',
    });

    const title = intl.formatMessage({
      id: 'ProductSelection.AddProductLabel',
      defaultMessage: 'Add a Product',
    });

    const dropdownOptions = products
      .filter(product => product.status === Constants.PRODUCT_STATUSES.NotAdded)
      .map(product => ({
        value: product.clientId,
        label: product.displayName || product.clientName,
      }));

    const dropdownValue = dropdownOptions.length === 1 ? dropdownOptions[0] : addProductValue;

    return (
      <Modal
        closeModal={this.onClose}
        id="product-button-add-modal"
        isOpen={isOpen}
        title={title}
        footerContent={(
          <Button
            id="product-button-add-modal-confirm"
            className="product-button-add-save-button"
            onClick={this.onAddProduct}
          >
            {saving
              ? <LoadingIcon />
              : <FormattedMessage id="Generic.Continue" defaultMessage="Continue" />}
          </Button>
)}
      >
        <div className="product-button-add-content">
          <div className="product-button-add-message">
            <FormattedMessage
              id="ProductSelection.AddProductMessage"
              defaultMessage="Choose a product from the list below. Clicking continue will add the product to the main page, and send you to the product."
            />
          </div>
          <Label
            id="product-button-add-dropdown-field"
            label={chooseProductLabel}
          >
            <AutoCompleteDropdown
              id="product-button-add-dropdown"
              placeholder=""
              options={dropdownOptions}
              onChange={this.handleDropdownChange}
              selected={dropdownValue}
              hasClearButton={false}
            />
          </Label>
        </div>
        {this.renderErrorField()}
      </Modal>
    );
  }
}

ProductButtonAddModal.propTypes = {
  isOpen: PropTypes.bool,
  onModalClose: PropTypes.func.isRequired,
  onProductsChanged: PropTypes.func.isRequired,
  products: PropTypes.arrayOf(PropTypes.shape({
    clientId: PropTypes.string,
    clientName: PropTypes.string,
    displayName: PropTypes.string,
    signInUrl: PropTypes.string,
    status: PropTypes.oneOf([
      Constants.PRODUCT_STATUSES.AutomaticallyAddedVerified,
      Constants.PRODUCT_STATUSES.AutomaticallyAddedNotVerified,
      Constants.PRODUCT_STATUSES.ManuallyAddedVerified,
      Constants.PRODUCT_STATUSES.ManuallyAddedNotVerified,
      Constants.PRODUCT_STATUSES.NotAdded,
    ]),
  })),
  intl: intlShape.isRequired,
};

ProductButtonAddModal.defaultProps = {
  isOpen: false,
  products: [],
};

export default injectIntl(ProductButtonAddModal);
