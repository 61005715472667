import { isProdEnvironment } from '../App/Utilities/environmentUtilities';

export const MOBILE_BREAKPOINT = 768;
export const TABLET_BREAKPOINT = 1024;
export const PAGE_SIZE_DEFAULT = 20;

export const GenericIconCodes = {
  default: 'accruent-greyscale-icon',
  assetManagement: 'asset-management-icon',
  capitalPlanning: 'capital-planning-icon',
  documentsManager: 'documents-manager-icon',
  lease: 'lease-icon',
  project: 'project-icon',
  workOrder: 'work-order-icon',
};

export const SaveModes = {
  create: 'create',
  edit: 'edit',
  view: 'view',
};

export const CookieComplianceUrl = isProdEnvironment
  ? 'https://cdn.cookielaw.org/consent/3bef6d98-f646-4153-b8c2-a13580e76808.js'
  : 'https://cdn.cookielaw.org/consent/7c67b286-7ec5-4639-9bc4-557dda5d94b8.js';

export const LoadingIconDelayMilliseconds = 1000;

export default MOBILE_BREAKPOINT;
