export const COMPLETE_REQUIRED_FIELDS = 'Must complete all required fields (*) before saving.';

export const TENANT_UUID_EMAIL_SUBJECT = tenantName => `QUESTION | Does UUUID exist for ${tenantName || '<Insert Tenant Name>'}`;

export const TENANT_UUID_EMAIL_BODY = tenantName => `I'm creating a tenant to use for Central Auth, is there an existing UUID for ${tenantName || '<Insert Tenant Name>'}?`;

export const NEED_UNIQUE_VALUE = fieldName => `Change the values above to create a unique ${fieldName}`;

export const HAS_UNEXPIRED_SERCRET = 'This product already has an associated secret. It is recommended that '
  + 'a product only have one active secret at a time. You can edit the existing secret’s expiration date to ensure '
  + 'multiple secrets do not exist simultaneously.';

export const CANCELED_UNMOUNTING = 'Operation canceled due to component unmounting.';

export const CANCELED_CLOSING = 'Operation canceled due to component closing.';

export const CANCELED_NEW_REQUEST = 'Operation canceled due to new request.';

export default COMPLETE_REQUIRED_FIELDS;
