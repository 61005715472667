import React, { Component } from 'react';
import queryString from 'query-string';
import { Button, Image } from '@accruent/acc-react-components';
import planeIcon from '../../../../resources/paper-plane.png';
import './SocialNotification.scss';

export default class SocialNotification extends Component {
  redirectToUrl = redirectUrl => {
    window.location.assign(redirectUrl);
  };

  render() {
    const socialTitle = 'UPCOMING EVENT: Accruent Insights!';
    const socialMessage = "Join us at Insights 2019 for three action-packed days in Denver, Colorado! At Accruent's annual customer conference you'll have the opportunity to learn technology best practices, about the latest product advancements, and network with both industry leaders and product developers.";
    const redirectButtonTitle = 'Visit the Event Page';
    const redirectUrl = 'https://insights.accruent.com';

    const queryValues = queryString.parse(window.location.search) || {};

    return queryValues.showNote ? (
      <div id="social-note" className="social-note">
        <div id="social-note-content" className="social-note-content">
          <Image src={planeIcon} alt="plane icon" className="social-note-icon" />
          <div id="social-note-title" className="social-note-title">
            {socialTitle}
          </div>
          <div id="social-note-message" className="social-note-message">
            {socialMessage}
          </div>
          {redirectButtonTitle && (
            <Button
              ariaLabel={redirectButtonTitle}
              onClick={() => this.redirectToUrl(redirectUrl)}
            >
              {redirectButtonTitle}
            </Button>
          )}
        </div>
        <div className="collapse-icon-div" />
      </div>
    ) : null;
  }
}
