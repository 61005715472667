import React, { Component, lazy, Suspense } from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import { Toast } from '@accruent/acc-react-components';
import { injectIntl, intlShape } from 'react-intl';
import DocumentTitle from 'react-document-title';
import HomePage from './Components/Templates/Instances/HomePage/HomePage';
import { isProdEnvironment } from './Utilities/environmentUtilities';
import * as Constants from '../constants';
import LoginPageLayout from './Components/Templates/LoginPageLayout/LoginPageLayout';
import BackgroundImage from '../resources/city_background.jpg';
import CookieComplianceBanner from './Components/Atoms/CookieComplianceBanner/CookieComplianceBanner';
import './App.css';

const ErrorPageLayout = lazy(() => import('./Components/Templates/ErrorPageLayout/ErrorPageLayout'));
const LogoutPageLayout = lazy(() => import('./Components/Templates/LogoutPageLayout/LogoutPageLayout'));
const AdminPageLayout = lazy(() => import('./Components/Templates/AdminPageLayout/AdminPageLayout'));
const TenantIdentityProviderTest = lazy(() => import('./Components/Organisms/Tenants/IdentityProviders/Test/TenantIdentityProviderTest'));

// react-axe warnings
(function enableAxeWarnings() {
  if (!isProdEnvironment) {
    // eslint-disable-next-line global-require, import/no-extraneous-dependencies
    const axe = require('@axe-core/react');
    axe(React, ReactDOM, 1000);
  }
}());

class App extends Component {
  render() {
    const { intl } = this.props;
    const titleMessage = {
      id: 'App.Title',
      defaultMessage: 'Accruent Central',
    };
    const styles = { backgroundImage: `url(${BackgroundImage})` };
    const loadingMessage = '';
    return (
      <div id="application" className="App-body" style={styles}>
        <Suspense fallback={<span>{loadingMessage}</span>}>
          <BrowserRouter>
            <Switch>
              <Route path={Constants.ROUTE_LOGIN} component={LoginPageLayout} />
              <Route path={Constants.ROUTE_ERROR} component={ErrorPageLayout} />
              <Route path={Constants.ROUTE_LOGGED_OUT} component={LogoutPageLayout} />
              <Route path={Constants.ROUTE_IDP_TEST_RESULTS} component={TenantIdentityProviderTest} />
              <Route path={Constants.ROUTE_ADMIN} component={AdminPageLayout} />
              <Route path="*" component={HomePage} />
            </Switch>
          </BrowserRouter>
        </Suspense>
        <Toast id="application-toast" breakpoint={Constants.MOBILE_BREAKPOINT} />
        <DocumentTitle title={intl.formatMessage(titleMessage)} />
        <CookieComplianceBanner />
      </div>
    );
  }
}

App.propTypes = {
  intl: intlShape.isRequired,
};

export default injectIntl(App);
