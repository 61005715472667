import React, { Component } from 'react';
import queryString from 'query-string';
import { ReactComponent as AccruentColorTextIcon } from '../../../../resources/products/AccruentColorTextIcon.svg';
import ApplicationFooter from '../../Organisms/ApplicationFooter/ApplicationFooter';
import LoginForm from '../../Organisms/LoginForm/LoginForm';
import CompanyFinder from '../../Organisms/CompanyFinder/CompanyFinder';
import SocialNotification from '../../Organisms/SocialNotification/SocialNotification';
import SystemAlert from '../../Organisms/SystemAlert/SystemAlert';
import './LoginPageLayout.scss';

export default class LoginPageLayout extends Component {
  constructor() {
    super();
    this.state = {
      tenantQuery: undefined,
      hideNotifications: false,
      acknowledgementNeeded: false,
    };
  }

  setTenantQuery = tenantQuery => {
    this.setState({ tenantQuery });
  };

  hideNotifications = () => {
    this.setState({ hideNotifications: true });
  };

  handleKeyUp = event => {
    if (event.keyCode === 13) {
      this.hideNotifications();
    }
  };

  setAcknowledgementNeeded = acknowledgementNeeded => {
    this.setState({ acknowledgementNeeded });
  };

  renderLoginContainer = (queryValues, showLoginForm) => {
    const { acknowledgementNeeded, tenantQuery } = this.state;

    return (
      <div id="login-container" className="login-container">
        <AccruentColorTextIcon className="login-container-logo" />
        {showLoginForm ? (
          <LoginForm
            tenantQuery={tenantQuery || { tenantUuid: queryValues.tenantuuid }}
            queryValues={queryValues}
            acknowledgementNeeded={acknowledgementNeeded}
          />
        ) : (
          <CompanyFinder onTenantFound={this.setTenantQuery} />
        )}
      </div>
    );
  };

  renderBottom = () => (
    <div id="application-footer">
      <ApplicationFooter />
    </div>
  );

  render() {
    const { hideNotifications, tenantQuery } = this.state;
    let queryValues;

    if (tenantQuery) {
      queryValues = tenantQuery;
    } else if (window.location.search) {
      queryValues = queryString.parse(window.location.search);
    }
    const showContainer = queryValues && (queryValues.showNote || queryValues.showAlert);
    const hideNotificationClass = hideNotifications || !showContainer ? 'hidden' : '';
    const showLoginForm = tenantQuery || (queryValues && (queryValues.ReturnUrl || queryValues.tenantuuid));

    return (
      <>
        <main id="application-body" className="aur-application-body-login">
          <div
            id="notification-container"
            className={`notification-container ${hideNotificationClass}`}
            onClick={this.hideNotifications}
            role="presentation"
            onKeyUp={this.handleKeyUp}
          >
            <SystemAlert setAcknowledgementNeeded={this.setAcknowledgementNeeded} />
            <SocialNotification />
            <div className={`corner-stamp-container ${showLoginForm ? 'hide-compact' : ''}`}>
              <div className="corner-stamp corner-stamp-large" />
              <div className="corner-stamp corner-stamp-medium" />
              <div className="corner-stamp corner-stamp-small" />
            </div>
          </div>
          {this.renderLoginContainer(queryValues, showLoginForm)}
        </main>
        {this.renderBottom()}
      </>
    );
  }
}
