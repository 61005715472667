import React, { Component } from 'react';
import { PropTypes } from 'prop-types';
import { Button } from '@accruent/acc-react-components';
import './IconTableRow.scss';

export default class IconTableRow extends Component {
  render() {
    const {
      ariaLabel, icon, id, onBlur, onClick, onFocus, onMouseOver, text,
    } = this.props;
    return (
      <Button
        ariaLabel={ariaLabel}
        id={`${id}-icon-table-row-button`}
        buttonType="borderless"
        className="aur-icon-table-row-button"
        fullWidth
        onBlur={onBlur}
        onClick={onClick}
        onFocus={onFocus}
        onMouseOver={onMouseOver}
      >
        <div id={`${id}-icon-table-row`} className="aur-icon-table-row">
          <span id={`${id}-icon-table-row-icon-container`} className="aur-icon-table-row-icon-container">
            {icon || (
            <div id={`${id}-icon-table-row-icon`} className="aur-icon-table-row-icon">
              <div className="aur-icon-table-row-icon-text">
                {typeof text === 'string' && text[0]}
              </div>
            </div>
            )}
          </span>
          <span className="aur-icon-table-row-text">
            {text}
          </span>
        </div>
      </Button>
    );
  }
}

IconTableRow.propTypes = {
  /** Accessibility label */
  ariaLabel: PropTypes.string,

  /** Icon image override. Can be any valid React element. */
  icon: PropTypes.element,

  /** Html Id */
  id: PropTypes.string.isRequired,

  /** Callback function called when button loses focus */
  onBlur: PropTypes.func,

  /** Callback function called when button is clicked */
  onClick: PropTypes.func,

  /** Callback function called when button is focused */
  onFocus: PropTypes.func,

  /** Callback function called when button is hovered over with the cursor */
  onMouseOver: PropTypes.func,

  /** Row text */
  text: PropTypes.string,
};

IconTableRow.defaultProps = {
  ariaLabel: undefined,
  icon: undefined,
  onBlur: undefined,
  onClick: undefined,
  onFocus: undefined,
  onMouseOver: undefined,
  text: undefined,
};
