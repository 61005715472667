import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Popover } from '@accruent/acc-react-components';
import FontAwesomeButton from '../../Atoms/FontAwesomeButton/FontAwesomeButton';

import './FontAwesomeMenuButton.scss';

export default class FontAwesomeMenuButton extends Component {
  constructor() {
    super();
    this.state = { buttonMenuOpen: false };
  }

    setInfoRef = element => {
      this.infoRef = element;
    };

    closeMenu = () => {
      this.setState({ buttonMenuOpen: false });
    };

    openButtonMenu = event => {
      this.setState({ buttonMenuOpen: true });
      event.stopPropagation();
    };

    closeOnBlur = (event = {}) => {
      const { id } = this.props;
      const popover = document.getElementById(`${id}-cen-button-popover-content`);
      const focusedPopoverItem = popover
            && popover.contains(event.relatedTarget || document.activeElement);
      if (!focusedPopoverItem) {
        this.closeMenu();
      }
    };

    renderInfoPopover = () => {
      const { buttonMenuOpen } = this.state;
      const { id, children } = this.props;
      return (
        buttonMenuOpen && (
        <Popover
          id={`${id}-cen-button-popover`}
          targetRef={this.infoRef}
          className="iconAnchor cen-button-popover"
        >
          <div
            id={`${id}-cen-button-popover-content`}
            className="cen-button-popover-content"
          >
            {children}
          </div>
        </Popover>
        )
      );
    };

    render() {
      const { id, icon, children } = this.props;
      return children ? (
        <span
          id={`${id}-cen-button-menu`}
          className="cen-button-menu-icon-container"
          ref={this.setInfoRef}
          onBlur={this.closeOnBlur}
        >
          <FontAwesomeButton
            id={`${id}-cen-button-menu-icon`}
            icon={icon}
            className="cen-button-menu-icon"
            onClick={this.openButtonMenu}
          />
          {this.renderInfoPopover()}
        </span>
      ) : null;
    }
}

FontAwesomeMenuButton.propTypes = {
  /** Id of the info element */
  id: PropTypes.string.isRequired,

  /** Valid FontAwesome icon */
  icon: PropTypes.shape({
    prefix: PropTypes.string,
    iconName: PropTypes.string,
    icon: PropTypes.arrayOf(PropTypes.any),
  }).isRequired,

  /** Tool tip element to be displayed */
  children: PropTypes.element,
};

FontAwesomeMenuButton.defaultProps = {
  children: undefined,
};
