import axios from 'axios';

export const makeApiGetCall = async (route, cancelToken, params) => {
  const response = await axios.get(route, {
    headers: { Pragma: 'no-cache' }, // Disable IE 11 chaching
    cancelToken,
    params,
  });
  return response.data;
};

export const makeApiPostCall = async (route, cancelToken, postBody, headers) => {
  const response = await axios.post(route, postBody, {
    headers,
    cancelToken,
  });
  return response.data;
};

export const makeApiPutCall = async (route, cancelToken, putBody, headers) => {
  const response = await axios.put(route, putBody, {
    headers,
    cancelToken,
  });
  return response.data;
};

export const makeApiPatchCall = async (route, cancelToken, patchBody, headers) => {
  const response = await axios.patch(route, patchBody, {
    headers,
    cancelToken,
  });
  return response.data;
};

export const makeApiDeleteCall = async (route, cancelToken) => {
  const response = await axios.delete(route, {
    cancelToken,
  });
  return response.data;
};

export const odataPageQuery = (pageSize, offset) => ({
  $top: pageSize,
  $skip: offset,
  $count: true,
});

export default makeApiGetCall;
