import React, { Component } from 'react';
import { PropTypes } from 'prop-types';
import './FormError.scss';

export default class FormError extends Component {
  render() {
    const { children, hidden, id } = this.props;
    const hiddenClass = hidden ? 'hidden' : '';
    return (
      <div id={id} className={`error-display ${hiddenClass}`}>
        {children}
      </div>
    );
  }
}

FormError.propTypes = {
  /** Error Text */
  children: PropTypes.node,

  /** Hide Component */
  hidden: PropTypes.bool,

  /** DOM Identifier */
  id: PropTypes.string.isRequired,
};

FormError.defaultProps = {
  hidden: true,
  children: '',
};
