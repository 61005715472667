import React, { Component } from 'react';
import { PropTypes } from 'prop-types';
// Product Icons
import { ReactComponent as AccruentGreyscaleIcon } from '../../../../resources/products/AccruentGreyscaleIcon.svg';
import { ReactComponent as BigCenterIcon } from '../../../../resources/products/BigCenterIcon.svg';
import { ReactComponent as ConnectIvIcon } from '../../../../resources/products/ConnectIvIcon.svg';
import { ReactComponent as EmsIcon } from '../../../../resources/products/EmsIcon.svg';
import { ReactComponent as Famis360Icon } from '../../../../resources/products/Famis360Icon.svg';
import { ReactComponent as Icon360Facility } from '../../../../resources/products/Icon360Facility.svg';
import { ReactComponent as KykloudIcon } from '../../../../resources/products/KykloudIcon.svg';
import { ReactComponent as LucernexIcon } from '../../../../resources/products/LucernexIcon.svg';
import { ReactComponent as MaintenanceConnectionIcon } from '../../../../resources/products/MaintenanceConnectionIcon.svg';
import { ReactComponent as MeridianIcon } from '../../../../resources/products/MeridianIcon.svg';
import { ReactComponent as SiterraIcon } from '../../../../resources/products/SiterraIcon.svg';
import { ReactComponent as TmsIcon } from '../../../../resources/products/TmsIcon.svg';
import { ReactComponent as VfaIcon } from '../../../../resources/products/VfaIcon.svg';
import { ReactComponent as VxFieldIcon } from '../../../../resources/products/VxFieldIcon.svg';
import { ReactComponent as VxMaintainIcon } from '../../../../resources/products/VxMaintainIcon.svg';

export default class ProductIcon extends Component {
  static recognizedUrl =
    url => (url || '').match(/bigcenter|connectiv|emssoftware|famis|360facility|kykloud|lucernex|maintenanceconnection|meridiancloud|siterra|tms|vfa|verisae/g);

  render() {
    const { url } = this.props;
    const defaultedUrl = url || '';

    let iconComponent = <AccruentGreyscaleIcon />;
    if (defaultedUrl.match(/bigcenter/g)) {
      iconComponent = <BigCenterIcon />;
    } else if (defaultedUrl.match(/connectiv/g)) {
      iconComponent = <ConnectIvIcon />;
    } else if (defaultedUrl.match(/emssoftware/g)) {
      iconComponent = <EmsIcon />;
    } else if (defaultedUrl.match(/famis/g)) {
      iconComponent = <Famis360Icon />;
    } else if (defaultedUrl.match(/360facility/g)) {
      iconComponent = <Icon360Facility />;
    } else if (defaultedUrl.match(/kykloud/g)) {
      iconComponent = <KykloudIcon />;
    } else if (defaultedUrl.match(/lucernex/g)) {
      iconComponent = <LucernexIcon />;
    } else if (defaultedUrl.match(/maintenanceconnection/g)) {
      iconComponent = <MaintenanceConnectionIcon />;
    } else if (defaultedUrl.match(/meridiancloud/g)) {
      iconComponent = <MeridianIcon />;
    } else if (defaultedUrl.match(/siterra/g)) {
      iconComponent = <SiterraIcon />;
    } else if (defaultedUrl.match(/tms/g)) {
      iconComponent = <TmsIcon />;
    } else if (defaultedUrl.match(/vfa/g)) {
      iconComponent = <VfaIcon />;
    } else if (defaultedUrl.match(/eam.verisae/g)) {
      iconComponent = <VxMaintainIcon />;
    } else if (defaultedUrl.match(/verisae/g)) {
      iconComponent = <VxFieldIcon />;
    }
    return iconComponent;
  }
}

ProductIcon.propTypes = {
  url: PropTypes.string,
};

ProductIcon.defaultProps = {
  url: '',
};
