export const IDENTITY_PROVIDER_RULE_TYPES = {
  Constant: 'Constant',
  Rename: 'Rename',
};

export const IDENTITY_PROVIDER_TYPES = {
  SAML: 'SAML',
  OIDC: 'OpenIdConnect',
};

export const SAML_TEST_RESULTS_COOKIE = 'saml_test_results_cookie';

export const IDENTITY_PROVIDER_DEFAULT_SAML_MAPPINGS = ['given_name', 'family_name', 'email'];

export const ADFS_NAME_ROUTE = '/adfs/';

export const ADFS_AUTO_RENAME_MAPPINGS = [
  { Rule: 'Rename', Name: 'given_name', Value: 'http://schemas.xmlsoap.org/ws/2005/05/identity/claims/givenname' },
  { Rule: 'Rename', Name: 'family_name', Value: 'http://schemas.xmlsoap.org/ws/2005/05/identity/claims/surname' },
  { Rule: 'Rename', Name: 'email', Value: 'http://schemas.xmlsoap.org/ws/2005/05/identity/claims/emailaddress' },
  { Rule: 'Rename', Name: 'alt_name', Value: 'http://schemas.xmlsoap.org/ws/2005/05/identity/claims/name' },
];

export const PRODUCT_ID_PREFIX = 'Accruent.Product.';

export const PRODUCT_NAMES = [
  { label: '360 Facility', value: '360Facility' },
  { label: 'Big Center', value: 'BigCenter' },
  { label: 'Connect IV', value: 'ConnectIV' },
  { label: 'EMS', value: 'EMS' },
  { label: 'FAMIS Cloud', value: 'FAMISCloud' },
  { label: 'Kykloud', value: 'Kykloud' },
  { label: 'Lucernex', value: 'Lucernex' },
  { label: 'Maintenance Connection', value: 'MaintenanceConnection' },
  { label: 'Meridian', value: 'Meridian' },
  { label: 'Siterra', value: 'Siterra' },
  { label: 'TMS', value: 'TMS' },
  { label: 'VFA', value: 'VFA' },
  { label: 'vxField', value: 'vxField' },
  { label: 'vxMaintain', value: 'vxMaintain' },
];

export const PRODUCT_REGIONS = [
  { label: 'US', value: 'US' },
  { label: 'UK', value: 'UK' },
  { label: 'EMEA', value: 'EMEA' },
  { label: 'APAC', value: 'APAC' },
];

export const PRODUCT_ENVIRONMENTS = [
  { label: 'Local', value: 'Local' },
  { label: 'Dev', value: 'Dev' },
  { label: 'Qa', value: 'Qa' },
  { label: 'Test', value: 'Test' },
  { label: 'Stage', value: 'Stage' },
  { label: 'Uat', value: 'Uat' },
  { label: 'Training', value: 'Training' },
  { label: 'Demo', value: 'Demo' },
  { label: 'Production', value: 'Production' },
];

export const PRODUCT_STATUSES = {
  AutomaticallyAddedVerified: 'AutomaticallyAddedVerified',
  AutomaticallyAddedNotVerified: 'AutomaticallyAddedNotVerified',
  ManuallyAddedVerified: 'ManuallyAddedVerified',
  ManuallyAddedNotVerified: 'ManuallyAddedNotVerified',
  NotAdded: 'NotAdded',
};

export const DEFAULT_PRODUCT_ALLOWED_SCOPES = [
  'openid',
  'offline_access',
  'profile',
  'accruent_claims',
];

export const ALLOWED_GRANT_TYPES = {
  AccruentClientCredentials: 'accruent_client_credentials',
  AuthorizationCode: 'authorization_code',
  ClientCredentials: 'client_credentials',
  Hybrid: 'hybrid',
  Implicit: 'implicit',
  Password: 'password',
  RefreshToken: 'refresh_token',
};

export const DEFAULT_PRODUCT_ALLOWED_GRANT_TYPES = [
  ALLOWED_GRANT_TYPES.AccruentClientCredentials,
  ALLOWED_GRANT_TYPES.RefreshToken,
  ALLOWED_GRANT_TYPES.ClientCredentials,
  ALLOWED_GRANT_TYPES.Hybrid,
];

export const DEFAULT_CLIENT_VALUES = {
  AllowOfflineAccess: true,
  UpdateAccessTokenClaimsOnRefresh: true,
  SlidingRefreshTokenLifetime: 300,
  AbsoluteRefreshTokenLifetime: 600,
  AlwaysIncludeUserClaimsInIdToken: true,
  AlwaysSendClientClaims: true,
  AllowAccessTokensViaBrowser: true,
};
