import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, injectIntl, intlShape } from 'react-intl';
import {
  AdaptiveResponse, Button, Input, LoadingIcon,
} from '@accruent/acc-react-components';
import queryString from 'query-string';
import axios from 'axios';
import { withRouter } from 'react-router-dom';
import { logIn } from '../../../../api/apiCalls';
import * as Constants from '../../../../constants';
import { displayNetworkError } from '../../../Utilities/networkUtilities';
import FormError from '../../Atoms/FormError/FormError';
import './AppLogin.scss';
import { isDevEnvironment } from '../../../Utilities/environmentUtilities';

class AppLogin extends Component {
  constructor() {
    super();

    this.state = {
      emailEdited: false,
      passwordEdited: false,
      emailField: undefined,
      passwordField: undefined,
      errorMessage: undefined,
    };
  }

  componentWillUnmount() {
    if (this.signal) {
      this.signal.cancel(Constants.CANCELED_UNMOUNTING);
    }
  }

  onEmailChange = event => {
    this.setState({
      emailEdited: true,
      emailField: event.target.value,
      errorMessage: '',
    });
  };

  onPasswordChange = event => {
    this.setState({
      passwordEdited: true,
      passwordField: event.target.value,
      errorMessage: '',
    });
  };

  redirectTo = redirectUri => {
    const { history } = this.props;

    if (isDevEnvironment) {
      document.cookie = 'idsrv.session=defacecafedeadbeeffadedfacadebad';
    }
    history.push(redirectUri);
  };

  logIn = async () => {
    const { emailField, passwordField } = this.state;
    const { acknowledgementNeeded, intl, tenantUuid } = this.props;
    const acknowledgementError = intl.formatMessage({
      id: 'Login.AcknowledgementRequired',
      defaultMessage:
        'You must acknowledge that you have read the system message to log in to Accruent Central.',
    });

    // Check acknowledgement requirement from System Message
    if (acknowledgementNeeded) {
      this.setState({
        errorMessage: acknowledgementError,
      });
      return;
    }

    // Cancel the previous request.
    if (this.signal) {
      this.signal.cancel(Constants.CANCELED_NEW_REQUEST);
    }
    // Save the new request for cancellation.
    this.signal = axios.CancelToken.source();

    let returnUrl = '';
    if (window.location.search) {
      const queryValues = queryString.parse(window.location.search);
      returnUrl = queryValues.ReturnUrl;
    }

    try {
      const data = await logIn(this.signal.token, emailField, passwordField, returnUrl, tenantUuid);

      if (data && data.userIsLoggedIn) {
        if (data.redirectUri) {
          window.location.assign(data.redirectUri);
        } else {
          this.redirectTo(Constants.ROUTE_BASE);
        }
      } else if (data && data.errorMessage) {
        this.setState({ errorMessage: data.errorMessage });
      }
    } catch (error) {
      displayNetworkError(error);
    }
  };

  renderWelcomeMessage = className => (
    <h1 className={`login-form-title ${className}`}>
      <FormattedMessage
        id="Greeting.WelcomeToAccruentEx"
        defaultMessage="Welcome to Accruent Central"
      />
    </h1>
  );

  renderLoginMessage = className => (
    <div className={`login-form-message ${className}`}>
      <FormattedMessage
        id="Login.OnePlace"
        defaultMessage="Log in to all your Accruent products in one place."
      />
    </div>
  )

  renderInputs = () => {
    const {
      emailEdited, emailField, passwordEdited, passwordField,
    } = this.state;
    const {
      intl, password, rememberLogin, username,
    } = this.props;
    const emailFieldDefault = emailField || (rememberLogin && !emailEdited && username) || '';
    const passwordFieldDefault = passwordField || (rememberLogin && !passwordEdited && password) || '';
    const emailPlaceholder = { id: 'Generic.Email', defaultMessage: 'Email' };
    const passwordPlaceholder = {
      id: 'Generic.Password',
      defaultMessage: 'Password',
    };
    return (
      <>
        <Input
          ariaLabel={intl.formatMessage(emailPlaceholder)}
          className="login-form-field"
          id="user-email-input"
          onEnterKey={this.logIn}
          onChange={this.onEmailChange}
          placeholder={intl.formatMessage(emailPlaceholder)}
          value={emailFieldDefault}
        />
        <Input
          ariaLabel={intl.formatMessage(passwordPlaceholder)}
          className="login-form-field"
          id="user-password-input"
          type="password"
          onEnterKey={this.logIn}
          onChange={this.onPasswordChange}
          placeholder={intl.formatMessage(passwordPlaceholder)}
          value={passwordFieldDefault}
        />
      </>
    );
  };

  renderForgotPassword = className => (
    <div className={`login-form-forgot-password ${className}`}>
      <a href="/login">
        <FormattedMessage id="Login.ForgotPassword" defaultMessage="Forgot password" />
      </a>
    </div>
  );

  renderButtons = className => {
    const { errorMessage } = this.state;
    const { acknowledgementNeeded, intl } = this.props;
    const acknowledgementError = intl.formatMessage({
      id: 'Login.AcknowledgementRequired',
      defaultMessage:
        'You must acknowledge that you have read the system message to log in to Accruent Central.',
    });

    const showError = errorMessage
      && errorMessage !== ''
      && !(errorMessage === acknowledgementError && !acknowledgementNeeded);
    const signInMessage = { id: 'Login.SignIn', defaultMessage: 'Sign In' };
    return (
      <>
        {className === 'compact' && this.renderForgotPassword(className)}
        <FormError id="login-form-error" hidden={!showError}>
          {errorMessage}
        </FormError>
        <Button id="login-form-button" onClick={this.logIn} fullWidth>
          {intl.formatMessage(signInMessage)}
        </Button>
        {className !== 'compact' && this.renderForgotPassword(className)}
      </>
    );
  };

  renderContent = (className = '') => {
    const { enableLocalLogin, loading } = this.props;
    return (
      <section className="login-form">
        {this.renderWelcomeMessage(className)}
        {loading
          ? (
            <div className="login-form-loading-icon-container">
              <LoadingIcon
                id="login-form-loading-icon"
                className="login-form-loading-icon"
              />
            </div>
          )
          : enableLocalLogin && (
          <>
            {this.renderLoginMessage(className)}
            {this.renderInputs()}
            {this.renderButtons(className)}
          </>
          )}
      </section>
    );
  };

  render() {
    const { breakpoint } = this.props;
    return (
      <AdaptiveResponse
        breakpoint={breakpoint}
        smallScreen={this.renderContent('compact')}
        largeScreen={this.renderContent()}
      />
    );
  }
}

AppLogin.propTypes = {
  acknowledgementNeeded: PropTypes.bool,
  breakpoint: PropTypes.number,
  enableLocalLogin: PropTypes.bool,
  // eslint-disable-next-line react/forbid-prop-types
  history: PropTypes.object.isRequired,
  intl: intlShape.isRequired,
  password: PropTypes.string,
  rememberLogin: PropTypes.bool,
  tenantUuid: PropTypes.string,
  username: PropTypes.string,
  loading: PropTypes.bool,
};

AppLogin.defaultProps = {
  acknowledgementNeeded: false,
  breakpoint: Constants.MOBILE_BREAKPOINT,
  enableLocalLogin: false,
  password: undefined,
  rememberLogin: false,
  tenantUuid: undefined,
  username: undefined,
  loading: false,
};

export default injectIntl(withRouter(AppLogin));
