import React, { Component } from 'react';
import { PropTypes } from 'prop-types';
import { injectIntl, intlShape } from 'react-intl';
import IconTableRow from '../../Molecules/IconTableRow/IconTableRow';
import * as Constants from '../../../../constants';

import './IdentityProviders.scss';

class IdentityProviders extends Component {
  logInExternal = provider => {
    const { tenantUuid, returnUrl } = this.props;

    const queryString = `?providerName=${encodeURIComponent(provider.authenticationScheme)}`
      + `&returnUrl=${encodeURIComponent(returnUrl)}`
      + `&tenantUuid=${tenantUuid}`
      + `&providerType=${encodeURIComponent(provider.providerType)}`;

    window.location.assign(Constants.LOGIN_EXTERNAL_ROUTE + queryString);
  };

  renderSignInMessage() {
    const { intl, isSecondaryOption } = this.props;
    const signInMessage = isSecondaryOption
      ? {
        id: 'Login.OrSignInUsing',
        defaultMessage: 'Or Sign In Using',
      }
      : {
        id: 'Login.SignInUsing',
        defaultMessage: 'Sign In Using:',
      };
    const secondaryClass = isSecondaryOption ? 'secondary' : '';

    return (
      <div className={`id-provider-title-container ${secondaryClass}`}>
        {isSecondaryOption && <div className="id-provider-separator" />}
        <div className="id-provider-title">{intl.formatMessage(signInMessage)}</div>
      </div>
    );
  }

  renderExternalProviders() {
    const { externalProviders } = this.props;
    return (
      externalProviders
      && externalProviders.map(provider => (
        <IconTableRow
          ariaLabel={`${provider.displayName}`}
          id={`${provider.displayName}-provider`}
          text={provider.displayName}
          icon={
            provider.providerLogo ? (
              <img
                src={`data:image/jpeg;base64,${provider.providerLogo}`}
                alt=""
              />
            ) : (
              undefined
            )
          }
          onClick={() => {
            this.logInExternal(provider);
          }}
          key={provider.displayName}
        />
      ))
    );
  }

  render() {
    return (
      <div className="id-provider-container">
        {this.renderSignInMessage()}
        {this.renderExternalProviders()}
      </div>
    );
  }
}

IdentityProviders.propTypes = {
  /** List of external identity providers. Format is array of
   * { displayName: string, authenticationScheme: string, providerType: string } */
  externalProviders: PropTypes.arrayOf(
    PropTypes.shape({
      displayName: PropTypes.string,
      authenticationScheme: PropTypes.string,
      providerType: PropTypes.string,
    }),
  ),
  intl: intlShape.isRequired,
  isSecondaryOption: PropTypes.bool,
  returnUrl: PropTypes.string,
  tenantUuid: PropTypes.string,
};

IdentityProviders.defaultProps = {
  externalProviders: undefined,
  isSecondaryOption: false,
  returnUrl: '',
  tenantUuid: '',
};

export default injectIntl(IdentityProviders);
