export const CACHE_COMMON_CLAIMS = 'commonClaims';

export const CACHE_TENANTS_FILTER = 'tenantsFilter';

export const CACHE_ASSOCIATED_TENANTS_FILTER = 'associatedTenantsFilter';

export const CACHE_HAS_NOTIFIED_COOKIE_USE = 'hasNotifiedCookieUse';

export const DEFAULT_TENANTS_FILTER = {
  filter: [{
    label: 'External Tenants',
    field: 'IsInternal',
    value: false,
    active: true,
  }, {
    label: 'Internal Tenants',
    field: 'IsInternal',
    value: true,
    active: true,
  },
  ],
};

export default CACHE_COMMON_CLAIMS;
