import React, { Component } from 'react';
import { PropTypes } from 'prop-types';
import { FormattedMessage } from 'react-intl';
import {
  Button, CheckboxOption, Input, Label, LoadingIcon, Modal,
} from '@accruent/acc-react-components';
import ErrorField from '../../Atoms/ErrorField/ErrorField';
import './ProductButtonDisplayModal.scss';
import ProductIconSelection from './ProductIconSelection';
import { cancelablePromise } from '../../../Utilities/javascriptUtilities';
import * as Constants from '../../../../constants';

class ProductButtonDisplayModal extends Component {
  constructor(props) {
    super();
    this.state = {
      buttonLabelInput: (props.product || {}).displayName,
      errorMessage: undefined,
      loading: false,
      selectedIconCode: (props.product || {}).iconId,
      secondaryLabelChecked: (props.product || {}).displayProductNameAsSecondary,
      showLoading: false,
    };
  }

  componentWillUnmount() {
    clearTimeout(this.saveTimeout);
    if (this.saveSignal) {
      this.saveSignal.cancel(Constants.CANCELED_UNMOUNTING);
    }
  }

  onSaveClick = async () => {
    const { buttonLabelInput, selectedIconCode, secondaryLabelChecked } = this.state;
    const {
      product, onModalClose, onProductsChanged, userDetails,
    } = this.props;

    this.saveTimeout = setTimeout(() => this.setState({ showLoading: true }), Constants.LoadingIconDelayMilliseconds);
    this.setState({ loading: true });

    if (userDetails) {
      const updatedProduct = {
        ...product,
        displayName: buttonLabelInput,
        iconId: selectedIconCode,
        displayProductNameAsSecondary: secondaryLabelChecked,
      };
      const productIndex = userDetails.products
        .findIndex(accruentProduct => (accruentProduct.clientId) === product.clientId);
      const updatedProducts = [...userDetails.products];
      updatedProducts[productIndex] = updatedProduct;

      this.saveSignal = cancelablePromise(onProductsChanged(updatedProducts));

      let errorMessage;
      try {
        errorMessage = await this.saveSignal.promise;
      } catch (error) {
        if (error.message === Constants.CANCELED_UNMOUNTING) {
          return;
        }
      }

      if (errorMessage) {
        this.setState({ errorMessage });
      } else {
        clearTimeout(this.saveTimeout);
        onModalClose();
        return;
      }
    } else {
      this.setState({
        errorMessage: <FormattedMessage
          id="ProductSelection.UserDataMissing"
          defaultMessage="User Data is missing from our records. Changes cannot be saved."
        />,
      });
    }
    clearTimeout(this.saveTimeout);
    this.setState({ loading: false, showLoading: false });
  };

  toggleSecondaryLabelChecked = () => {
    const { secondaryLabelChecked } = this.state;
    this.setState({ secondaryLabelChecked: !secondaryLabelChecked });
  }

  updateLabel = event => {
    this.setState({ buttonLabelInput: event.target.value });
  };

  handleIconSelected = iconCode => {
    this.setState({ selectedIconCode: iconCode });
  };

  renderErrorField = () => {
    const { errorMessage } = this.state;

    return (
      <ErrorField title={(
        <FormattedMessage
          id="ProductSelection.ErrorSavingSettings"
          defaultMessage="An error has occurred attempting to save these user settings."
        />
)}
      >
        {errorMessage}
      </ErrorField>
    );
  };

  render() {
    const {
      buttonLabelInput, loading, showLoading, selectedIconCode, secondaryLabelChecked,
    } = this.state;
    const { onModalClose, product } = this.props;

    return (
      <Modal
        closeModal={onModalClose}
        id="product-button-display"
        isOpen={typeof product !== 'undefined'}
        title={(
          <FormattedMessage
            id="ProductSelection.ProductButtonDisplay"
            defaultMessage="Product Button Display"
          />
)}
        footerContent={(
          <Button
            id="product-button-display-save"
            className="product-button-display-save-button"
            onClick={this.onSaveClick}
            disabled={loading}
          >
            {showLoading ? <LoadingIcon /> : 'Save'}
          </Button>
)}
      >
        <Label // Button Label
          id="product-button-display-label-field"
          label={(
            <FormattedMessage
              id="ProductSelection.ButtonLabel"
              defaultMessage="Button Label"
            />
)}
        >
          <Input
            id="product-button-display-label-input"
            value={buttonLabelInput || ''}
            placeholder=""
            onEnterKey={this.onSaveClick}
            onChange={this.updateLabel}
            maxLength={200}
            ariaLabelledBy="product-button-display-label-field-label"
          />
        </Label>
        <Label // Choose an Image
          id="product-button-display-image-field"
          className="product-button-display-image-field"
          label={(
            <FormattedMessage
              id="ProductSelection.ChooseImage"
              defaultMessage="Choose an Image"
            />
)}
        >
          <ProductIconSelection
            key={(product || {}).iconId}
            onIconSelected={this.handleIconSelected}
            selectedIconKey={selectedIconCode}
            signInUrl={(product || {}).signInUrl}
          />
        </Label>
        <Label // Product Name
          id="product-button-display-name-field"
          className="product-button-display-name-field"
          label={(
            <FormattedMessage
              id="ProductSelection.ProductName"
              defaultMessage="Product Name"
            />
)}
        >
          <Input
            id="product-button-display-name-input"
            className="product-button-display-name-input"
            value={(product || {}).clientName}
            ariaLabelledBy="product-button-display-name-field-label"
            disabled
            placeholder=""
          />
        </Label>
        <CheckboxOption
          id="product-button-display-secondary-label"
          className="product-button-display-secondary-label"
          label={(
            <FormattedMessage
              id="ProductSelection.DisplaySecondaryLabel"
              defaultMessage="Display Product Name as Secondary Label"
            />
)}
          name="product-button-display-secondary-label"
          checked={secondaryLabelChecked}
          onChange={this.toggleSecondaryLabelChecked}
          value="product-button-display-secondary-label"
        />
        {this.renderErrorField()}
      </Modal>
    );
  }
}

ProductButtonDisplayModal.propTypes = {
  product: PropTypes.shape({
    clientId: PropTypes.string,
    clientName: PropTypes.string,
    displayName: PropTypes.string,
    displayProductNameAsSecondary: PropTypes.bool,
    iconId: PropTypes.string,
    signInUrl: PropTypes.string,
  }),
  userDetails: PropTypes.shape({
    userName: PropTypes.string,
    products: PropTypes.arrayOf(
      PropTypes.shape({
        clientId: PropTypes.string,
        clientName: PropTypes.string,
        displayName: PropTypes.string,
        displayProductNameAsSecondary: PropTypes.bool,
        iconId: PropTypes.string,
        signInUrl: PropTypes.string,
      }),
    ),
  }),
  onModalClose: PropTypes.func.isRequired,
  onProductsChanged: PropTypes.func.isRequired,
};

ProductButtonDisplayModal.defaultProps = {
  product: undefined,
  userDetails: undefined,
};

export default ProductButtonDisplayModal;
