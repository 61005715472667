import React, { Component } from 'react';
import { PropTypes } from 'prop-types';
import { ReactComponent as AccruentGreyscaleIcon } from '../../../../resources/products/AccruentGreyscaleIcon.svg';
import { ReactComponent as AssetManagementIcon } from '../../../../resources/products/generic/AssetManagementIcon.svg';
import { ReactComponent as CapitalPlanIcon } from '../../../../resources/products/generic/CapitalPlanIcon.svg';
import { ReactComponent as DocumentsManagerIcon } from '../../../../resources/products/generic/DocumentsManagerIcon.svg';
import { ReactComponent as LeaseIcon } from '../../../../resources/products/generic/LeaseIcon.svg';
import { ReactComponent as ProjectIcon } from '../../../../resources/products/generic/ProjectIcon.svg';
import { ReactComponent as WorkOrderIcon } from '../../../../resources/products/generic/WorkOrderIcon.svg';
import { GenericIconCodes } from '../../../../constants/configurationConstants';

export default class GenericIcon extends Component {
  render() {
    const { iconCode } = this.props;

    switch (iconCode) {
      case GenericIconCodes.assetManagement:
        return <AssetManagementIcon key={iconCode} />;
      case GenericIconCodes.capitalPlanning:
        return <CapitalPlanIcon key={iconCode} />;
      case GenericIconCodes.documentsManager:
        return <DocumentsManagerIcon key={iconCode} />;
      case GenericIconCodes.lease:
        return <LeaseIcon key={iconCode} />;
      case GenericIconCodes.project:
        return <ProjectIcon key={iconCode} />;
      case GenericIconCodes.workOrder:
        return <WorkOrderIcon key={iconCode} />;
      case GenericIconCodes.default:
      default:
        return <AccruentGreyscaleIcon key={iconCode} />;
    }
  }
}

GenericIcon.propTypes = {
  iconCode: PropTypes.string,
};

GenericIcon.defaultProps = {
  iconCode: undefined,
};
