import React, { Component } from 'react';
import { PropTypes } from 'prop-types';
import axios from 'axios';
import { withRouter } from 'react-router-dom';
import { loadUserDetails } from '../../../../../api/apiCalls';
import HomeLayout from '../../HomeLayout/HomeLayout';
import AppGreeting from '../../../Organisms/AppGreeting/AppGreeting';
import ProductSelection from '../../../Organisms/ProductSelection/ProductSelection';
import { displayNetworkError } from '../../../../Utilities/networkUtilities';
import '../../../../../styles/scss/index.scss';
import { hasLoginCookie } from '../../../../Utilities/accountUtilities';
import * as Constants from '../../../../../constants';

class HomePage extends Component {
  constructor() {
    super();
    this.state = {
      loading: false,
      userDetails: {},
    };
  }

  componentDidMount = async () => {
    if (this.isLoggedIn()) {
      this.setState({ loading: true });
      await this.onLoadUserDetails();
      this.setState({ loading: false });
    }
  }

  componentWillUnmount() {
    if (this.signal) {
      this.signal.cancel(Constants.CANCELED_UNMOUNTING);
    }
  }

  onLoadUserDetails = async () => {
    // Cancel the previous request.
    if (this.signal) {
      this.signal.cancel(Constants.CANCELED_NEW_REQUEST);
    }

    // Save the new request for cancellation.
    this.signal = axios.CancelToken.source();

    let userDetails;
    try {
      userDetails = await loadUserDetails(this.signal.token);
      this.setState({ userDetails });
    } catch (error) {
      displayNetworkError(error);
    }

    return userDetails;
  };

  redirectToLogin = () => {
    const { history } = this.props;
    history.replace(Constants.ROUTE_LOGIN);
  };

  isLoggedIn = () => {
    if (hasLoginCookie() === null) {
      this.redirectToLogin();
    }
    return hasLoginCookie() !== null;
  };

  handleProductsChanged = updatedProducts => {
    const { userDetails } = this.state;

    this.setState({
      userDetails: { ...userDetails, products: updatedProducts },
    });
  }

  render() {
    const {
      loading, userDetails,
    } = this.state;
    const { breakpoint, breakpointMedium } = this.props;
    return (
      <HomeLayout
        breakpoint={breakpoint}
        breakpointMedium={breakpointMedium}
        loading={loading}
        userDetails={userDetails}
        logoImage={userDetails && userDetails.tenant && userDetails.tenant.landingPageImage}
        titleChildren={(
          <AppGreeting
            userFirstName={userDetails && userDetails.firstName}
            breakpoint={breakpoint}
          />
)}
        contentChildren={(
          <ProductSelection
            loading={loading}
            onProductsChanged={this.handleProductsChanged}
            userDetails={userDetails}
          />
)}
      />
    );
  }
}

HomePage.propTypes = {
  breakpoint: PropTypes.number,
  breakpointMedium: PropTypes.number,
  // eslint-disable-next-line react/forbid-prop-types
  history: PropTypes.object.isRequired,
};

HomePage.defaultProps = {
  breakpoint: Constants.MOBILE_BREAKPOINT,
  breakpointMedium: Constants.TABLET_BREAKPOINT,
};

export default withRouter(HomePage);
