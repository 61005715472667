/* eslint-disable no-plusplus */
/* eslint-disable no-bitwise */
/* eslint-disable func-names */

export function hashCode(inputString) {
  let hash = 0;
  let i;
  let chr;
  if (inputString.length === 0) return hash;
  for (i = 0; i < inputString.length; i++) {
    chr = inputString.charCodeAt(i);
    hash = (hash << 5) - hash + chr;
    hash |= 0; // Convert to 32bit integer
  }
  return hash;
}

export const cancelablePromise = promise => {
  let cancelationReason = false;
  const wrappedPromise = new Promise((resolve, reject) => {
    if (!promise || typeof promise.then !== 'function') {
      resolve(promise);
    }
    promise.then(
      val => (cancelationReason !== false ? reject(new Error(cancelationReason)) : resolve(val)),
      error => (cancelationReason !== false ? reject(new Error(cancelationReason)) : reject(error)),
    );
  });

  return {
    promise: wrappedPromise,
    cancel(reason) {
      cancelationReason = reason;
    },
  };
};

export default hashCode;
