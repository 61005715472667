import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import * as Constants from '../../../../constants';

const ErrorMessage = props => {
  const { id } = props;
  const endSentencePunctuation = '.';
  return (
    <div id={id}>
      <FormattedMessage
        id="Error.GenericError"
        defaultMessage="An error has occurred."
      />
      {' '}
      <FormattedMessage
        id="Error.RequireAssistance"
        defaultMessage="If you require assistance, please contact support at "
      />
      <a href={`mailto:${Constants.SUPPORT_EMAIL}`}>
        {Constants.SUPPORT_EMAIL}
      </a>
      {endSentencePunctuation}
    </div>
  );
};

ErrorMessage.propTypes = {
  id: PropTypes.string.isRequired,
};

export default ErrorMessage;
