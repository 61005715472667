import { Component } from 'react';
import * as Constants from '../../../../constants';

export default class CookieComplianceBanner extends Component {
  componentDidMount() {
    this.checkCookieCompliance();
  }

  checkCookieCompliance = () => {
    const hasNotifiedCookieUse = localStorage.getItem(Constants.CACHE_HAS_NOTIFIED_COOKIE_USE);
    if (hasNotifiedCookieUse !== 'true') {
      // Append OneTrust Cookie Conset script
      const script = document.createElement('script');
      script.src = Constants.CookieComplianceUrl;
      script.async = true;
      script.onload = () => localStorage.setItem(Constants.CACHE_HAS_NOTIFIED_COOKIE_USE, 'true');
      document.head.appendChild(script);
    }
  }

  render() {
    return null;
  }
}
