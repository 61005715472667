import React, { Component } from 'react';
import { PropTypes } from 'prop-types';
import './ErrorField.scss';

export default class ErrorField extends Component {
  render() {
    const { id, children, title } = this.props;
    return children ? (
      <div id={id} className="error-field-container">
        <div className="error-field">
          {title && <div className="error-field-title">{title}</div>}
          {children}
        </div>
      </div>
    ) : null;
  }
}

ErrorField.propTypes = {
  id: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.object, PropTypes.node]),
  title: PropTypes.string,
};

ErrorField.defaultProps = {
  id: '',
  children: undefined,
  title: undefined,
};
