import React, { Component } from 'react';
import { PropTypes } from 'prop-types';
import axios from 'axios';
import { FormattedMessage } from 'react-intl';
import {
  Button, ErrorIcon, AccFontAwesomeIcon, LoadingIcon, Modal, openToast,
} from '@accruent/acc-react-components';
import { faEllipsisH } from '@fortawesome/free-solid-svg-icons';
import { putUserProducts, postLandingPageMetrics } from '../../../../api/apiCalls';
import { combineErrorMessages } from '../../../Utilities/networkUtilities';
import * as Constants from '../../../../constants';
import * as Console from '../../../Utilities/Console';
import ProductIcon from '../../Atoms/ProductIcon/ProductIcon';
import FontAwesomeMenuButton from '../../Molecules/FontAwesomeMenuButton/FontAwesomeMenuButton';
import ProductButtonDisplayModal from './ProductButtonDisplayModal';
import ProductButtonAddModal from './ProductButtonAddModal';
import GenericIcon from '../../Atoms/GenericIcon/GenericIcon';
import { ReactComponent as StarCircleIcon } from '../../../../resources/StarCircleIcon.svg';
import { ReactComponent as ErrorCircleIcon } from '../../../../resources/ErrorCircleIcon.svg';
import './ProductSelection.scss';
import '../../../../styles/scss/modalDelete.scss';

export default class ProductSelection extends Component {
  constructor() {
    super();
    this.state = {
      selectedProduct: undefined,
      removeClientId: undefined,
      removing: false,
      showAddProductModal: false,
    };
  }

  componentWillUnmount() {
    if (this.signal) {
      this.signal.cancel(Constants.CANCELED_UNMOUNTING);
    }

    if (this.metricsSignal) {
      this.metricsSignal.cancel(Constants.CANCELED_UNMOUNTING);
    }
  }

  navigateToProductUrl = product => {
    if (product.signInUrl) {
      window.open(product.signInUrl, '_blank');
      this.updateProductMetrics(product);
    } else {
      openToast({
        type: 'error',
        centerNode: <FormattedMessage
          id="ProductSelection.UrlNotFound"
          defaultMessage="We did not find a configured URL for this product, please reach out to your administrator."
        />,
      });
    }
  };

  showProductOptionsModal = product => {
    this.setState({ selectedProduct: product });
  };

  updateUserProducts = async products => {
    const { onProductsChanged, userDetails } = this.props;

    const filteredProducts = products.filter(product => product.status !== Constants.PRODUCT_STATUSES.NotAdded);

    // Cancel the previous request.
    if (this.signal) {
      this.signal.cancel(Constants.CANCELED_NEW_REQUEST);
    }

    // Save the new request for cancellation.
    this.signal = axios.CancelToken.source();

    try {
      await putUserProducts(this.signal.token, filteredProducts, userDetails.userName);
      onProductsChanged(products);
      return null;
    } catch (error) {
      return combineErrorMessages(error);
    }
  };

  updateProductMetrics = async product => {
    // Cancel the previous request.
    if (this.metricsSignal) {
      this.metricsSignal.cancel(Constants.CANCELED_NEW_REQUEST);
    }

    // Save the new request for cancellation.
    this.metricsSignal = axios.CancelToken.source();

    const metrics = {
      ClientName: product.clientName,
    };

    try {
      await postLandingPageMetrics(this.metricsSignal.token, metrics);
    } catch (error) {
      Console.error(error);
    }
  };

  onRemoveProduct = async clientId => {
    const { userDetails } = this.props;

    const updatedProductIndex = userDetails.products.findIndex(product => product.clientId === clientId);
    const updatedProducts = [...userDetails.products];
    updatedProducts[updatedProductIndex] = {
      ...userDetails.products[updatedProductIndex],
      status: Constants.PRODUCT_STATUSES.NotAdded,
    };

    const removingTimeout = setTimeout(
      () => this.setState({ removing: true }),
      Constants.LoadingIconDelayMilliseconds,
    );

    const error = await this.updateUserProducts(updatedProducts);
    if (error) {
      openToast({
        type: 'error',
        centerNode:
  <>
    <FormattedMessage
      id="ProductSelection.ErrorRemoving"
      defaultMessage="There was a problem removing this product:"
    />
    {` ${error}`}
  </>,
      });
    }

    clearTimeout(removingTimeout);
    this.setState({ removing: false, removeClientId: undefined });
  };

  renderProductMenu = product => (
    <>
      <Button
        id="product-menu-edit-button"
        className="open-menu-button"
        ariaLabel="edit button"
        buttonType="borderless"
        onClick={event => {
          this.showProductOptionsModal(product);
          event.stopPropagation();
        }}
      >
        <FormattedMessage
          id="ProductSelection.EditLabel"
          defaultMessage="Edit Product"
        />
      </Button>
      <Button
        id="product-menu-remove-button"
        className="open-menu-button"
        ariaLabel="edit button"
        buttonType="borderless"
        onClick={event => {
          this.setState({ removeClientId: product.clientId });
          event.stopPropagation();
        }}
      >
        <FormattedMessage
          id="ProductSelection.RemoveLabel"
          defaultMessage="Remove Product"
        />
      </Button>
    </>
  );

  renderProductsTitle = (products, visibleProducts) => {
    const { loading } = this.props;
    const titleHiddenClass = products.length === 0 ? 'hidden' : '';
    const titleMessageHiddenClass = loading || visibleProducts.length < 1 ? 'hidden' : '';
    const addHiddenClass = loading || visibleProducts.length === products.length ? 'hidden' : '';

    return (
      <div className={`product-selection-title ${titleHiddenClass}`}>
        <span className={`product-selection-title-message ${titleMessageHiddenClass}`}>
          <FormattedMessage
            id="ProductSelection.SelectProduct"
            defaultMessage="Select the product you wish to use."
          />
        </span>
        <Button
          id="product-selection-add-product-button"
          onClick={() => this.setState({ showAddProductModal: true })}
          buttonType="borderless"
          className={addHiddenClass}
        >
          <span className="right-bar-item-content" tabIndex={-1}>
            <AccFontAwesomeIcon
              id="product-selection-add-product-button-icon"
              iconCode="plus-circle"
            />
            <FormattedMessage
              id="ProductSelection.AddProductLabel"
              defaultMessage="Add a Product"
            />
          </span>
        </Button>
      </div>
    );
  };

  renderCellInfoFooter = () => (
    <div className="product-selection-list-cell-footer-info">
      <FormattedMessage
        id="ProductSelection.NewProduct"
        defaultMessage="NEW PRODUCT!"
      />
      <FormattedMessage
        id="ProductSelection.ClickToValidate"
        defaultMessage="Click to validate"
      />
    </div>
  );

  renderCellErrorFooter = () => (
    <div className="product-selection-list-cell-footer-error">
      <div>
        <AccFontAwesomeIcon
          id="product-selection-list-cell-footer-error-icon"
          iconCode="exclamation-triangle"
          role="presentation"
        />
        <FormattedMessage
          id="ProductSelection.UrlNotFound2"
          defaultMessage="URL cannot be found. Contact your administrator for help."
        />
      </div>
    </div>
  );

  renderProducts = () => {
    const { userDetails } = this.props;
    const products = userDetails.products || [];
    const visibleProducts = products.filter(product => product.status !== Constants.PRODUCT_STATUSES.NotAdded);

    return (
      <div>
        {this.renderProductsTitle(products, visibleProducts)}
        <div className="product-selection-list">
          {visibleProducts
            && visibleProducts.map(product => {
              const title = product.displayName || product.clientName;
              const fontSizeClass = product.clientName && product.clientName.length > 11 ? 'smaller-font' : '';
              const iconClass = ProductIcon.recognizedUrl(product.signInUrl) ? 'custom-logo' : '';
              const unverified = product.status === Constants.PRODUCT_STATUSES.ManuallyAddedNotVerified
                || product.status === Constants.PRODUCT_STATUSES.AutomaticallyAddedNotVerified;
              const singleLineClass = !product.signInUrl || unverified ? 'single-line-clamp' : '';
              const footerNotice = product.signInUrl
                ? unverified && this.renderCellInfoFooter()
                : this.renderCellErrorFooter();

              return (
                <div
                  className="product-selection-list-cell"
                  key={`${product.clientId}-${product.signInUrl}`}
                  role="button"
                  tabIndex={0}
                  onKeyUp={event => event.keyCode === 13 && this.navigateToProductUrl(product)}
                  onClick={() => this.navigateToProductUrl(product)}
                >
                  {product.signInUrl
                    ? unverified && <StarCircleIcon className="product-selection-list-cell-decoration" />
                    : <ErrorCircleIcon className="product-selection-list-cell-decoration" />}
                  <FontAwesomeMenuButton
                    id={`product-selection-list-cell-menu-button-${product.clientName}`}
                    icon={faEllipsisH}
                  >
                    {this.renderProductMenu(product)}
                  </FontAwesomeMenuButton>
                  <span className={`product-selection-list-cell-icon ${iconClass}`}>
                    {product.iconId
                      ? <GenericIcon iconCode={product.iconId} />
                      : <ProductIcon url={product.signInUrl} />}
                  </span>
                  {title && <span className="product-selection-list-cell-divider" />}
                  <span className={`product-selection-list-cell-title ${fontSizeClass} ${singleLineClass}`}>
                    {title}
                  </span>
                  {footerNotice || (product.displayProductNameAsSecondary && product.displayName
                    && (
                    <span className="product-selection-list-cell-subtitle">
                      {product.clientName}
                    </span>
                    ))}
                </div>
              );
            })}
        </div>
      </div>
    );
  };

  renderNoProductsMessage = () => {
    const { userDetails, loading } = this.props;
    const products = userDetails.products || [];
    const hasProducts = products.some(product => product.status !== Constants.PRODUCT_STATUSES.NotAdded);

    const endSentencePunctuation = '.';
    const hiddenClass = !loading && (!hasProducts) ? '' : 'hidden';
    return (
      <span>
        <h2 className={`no-products-message ${hiddenClass}`}>
          <FormattedMessage
            id="ProductSelection.NoProducts"
            defaultMessage="You may now leave this page to access an Accruent product."
          />
          {' '}
          <FormattedMessage
            id="Error.RequireAssistance"
            defaultMessage="If you require assistance, please contact support at "
          />
          <a href={`mailto:${Constants.SUPPORT_EMAIL}`}>{Constants.SUPPORT_EMAIL}</a>
          {endSentencePunctuation}
        </h2>
      </span>
    );
  };

  renderRemoveProductModal = () => {
    const { removeClientId, removing } = this.state;

    return (
      <Modal
        closeModal={() => this.setState({ removeClientId: undefined })}
        id="modal-delete"
        isOpen={removeClientId}
        footerContent={(
          <Button
            id="modal-footer-delete"
            className="product-selection-delete-button"
            onClick={async () => this.onRemoveProduct(removeClientId)}
          >
            {removing
              ? <LoadingIcon />
              : <FormattedMessage id="ProductSelection.RemoveLabel" defaultMessage="Remove Product" />}
          </Button>
)}
      >
        <div className="product-selection-remove">
          <ErrorIcon id="product-selection-remove-icon" className="product-selection-remove-icon" />
          <div className="product-selection-remove-title">
            <FormattedMessage
              id="ProductSelection.ConfirmSure"
              defaultMessage="Are you sure?"
            />
          </div>
          <div className="product-selection-remove-body">
            <FormattedMessage
              id="ProductSelection.CannotUndo"
              defaultMessage="You cannot undo this action."
            />
            <br />
            <FormattedMessage
              id="ProductSelection.ConfirmRemove"
              defaultMessage="Do you want to remove this product button?"
            />
          </div>
        </div>
      </Modal>
    );
  };

  render() {
    const { selectedProduct, showAddProductModal } = this.state;
    const {
      loading, userDetails,
    } = this.props;

    return (
      <div className="product-selection">
        {loading && <LoadingIcon />}
        {this.renderProducts()}
        {this.renderNoProductsMessage()}
        <ProductButtonDisplayModal
          key={(selectedProduct || {}).clientId}
          product={selectedProduct}
          onModalClose={() => this.setState({ selectedProduct: undefined })}
          onProductsChanged={this.updateUserProducts}
          userDetails={userDetails}
        />
        {this.renderRemoveProductModal()}
        <ProductButtonAddModal
          products={userDetails.products}
          onProductsChanged={this.updateUserProducts}
          onModalClose={() => this.setState({ showAddProductModal: false })}
          isOpen={showAddProductModal}
        />
      </div>
    );
  }
}

ProductSelection.propTypes = {
  userDetails: PropTypes.shape({
    userName: PropTypes.string,
    products: PropTypes.arrayOf(
      PropTypes.shape({
        clientId: PropTypes.string,
        clientName: PropTypes.string,
        displayName: PropTypes.string,
        displayProductNameAsSecondary: PropTypes.bool,
        iconId: PropTypes.string,
        signInUrl: PropTypes.string,
        status: PropTypes.oneOf([
          Constants.PRODUCT_STATUSES.AutomaticallyAddedVerified,
          Constants.PRODUCT_STATUSES.AutomaticallyAddedNotVerified,
          Constants.PRODUCT_STATUSES.ManuallyAddedVerified,
          Constants.PRODUCT_STATUSES.ManuallyAddedNotVerified,
          Constants.PRODUCT_STATUSES.NotAdded,
        ]).isRequired,
      }),
    ),
    tenant: PropTypes.shape({
      uuid: PropTypes.string,
    }),
  }),
  loading: PropTypes.bool,
  onProductsChanged: PropTypes.func.isRequired,
};

ProductSelection.defaultProps = {
  userDetails: {},
  loading: false,
};
