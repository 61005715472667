import React, { Component } from 'react';
import { PropTypes } from 'prop-types';
import { AdaptiveResponse } from '@accruent/acc-react-components';
import ApplicationHeader from '../../Organisms/ApplicationHeader/ApplicationHeader';
import ApplicationFooter from '../../Organisms/ApplicationFooter/ApplicationFooter';
import * as Constants from '../../../../constants';
import './HomeLayout.scss';

export default class HomeLayout extends Component {
  redirectToHome = () => {
    window.location.assign(Constants.ROUTE_BASE);
  };

  renderTop = () => {
    const {
      breakpoint, breakpointMedium, loading, logoImage, userDetails,
    } = this.props;
    return (
      <ApplicationHeader
        userDetails={userDetails}
        loading={loading}
        breakpoint={breakpoint}
        breakpointMedium={breakpointMedium}
        onLogoClick={this.redirectToHome}
        logo={logoImage && <img src={`data:image/jpeg;base64,${logoImage}`} alt="tenant logo" />}
      />
    );
  };

  renderBody = (className = '') => {
    const { breakpoint, contentChildren, titleChildren } = this.props;

    return (
      <div id="home-layout" className="home-layout">
        <main>
          <h1 id="title-layout" className={`title-layout ${className}`}>
            {titleChildren}
          </h1>
          <div id="content-layout" className={`content-layout ${className}`}>
            {contentChildren}
          </div>
        </main>
        <ApplicationFooter breakpoint={breakpoint} />
      </div>
    );
  };

  render() {
    const { breakpoint, className } = this.props;

    return (
      <div id="conetent-layout-container" className={className}>
        {this.renderTop()}
        <AdaptiveResponse
          breakpoint={breakpoint}
          smallScreen={this.renderBody('compact')}
          largeScreen={this.renderBody()}
        />
      </div>
    );
  }
}

HomeLayout.propTypes = {
  breakpoint: PropTypes.number,
  breakpointMedium: PropTypes.number,
  className: PropTypes.string,
  contentChildren: PropTypes.node.isRequired,
  loading: PropTypes.bool,
  titleChildren: PropTypes.node.isRequired,
  userDetails: PropTypes.shape({
    firstName: PropTypes.string,
    title: PropTypes.string,
    userName: PropTypes.string,
  }),
  logoImage: PropTypes.string,
};

HomeLayout.defaultProps = {
  breakpoint: Constants.MOBILE_BREAKPOINT,
  breakpointMedium: Constants.TABLET_BREAKPOINT,
  className: '',
  loading: false,
  userDetails: undefined,
  logoImage: undefined,
};
