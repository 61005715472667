import {
  makeApiGetCall,
  makeApiPostCall,
  odataPageQuery,
  makeApiDeleteCall,
  makeApiPutCall,
} from './apiHelper';
import * as Constants from '../constants';

/** Login */
export const loadUserDetails = async cancelToken => {
  const data = await makeApiGetCall(Constants.USER_DETAILS_ROUTE, cancelToken);

  // Create composite field "userName"
  if (data && data.firstName && data.lastName) {
    data.fullName = `${data.firstName} ${data.lastName}`;
  }

  return data;
};

export const loadLoginViewModel = async (cancelToken, queryParams) => {
  const data = await makeApiGetCall(
    Constants.LOGIN_VIEW_MODEL_ROUTE,
    cancelToken,
    queryParams,
  );

  // Sort providers to show SAML type first (descending), then by displayName (ascending)
  if (data && data.visibleExternalProviders) {
    data.visibleExternalProviders = data.visibleExternalProviders.sort(
      (provider1, provider2) => (provider2.providerType || '').localeCompare(provider1.providerType)
        || (provider1.displayName || '').localeCompare(provider2.displayName),
    );
  }
  return data;
};

export const logIn = async (cancelToken, userName, password, returnUrl, tenantUuid) => {
  const headers = { 'Content-Type': 'application/x-www-form-urlencoded' };
  const payload = `ReturnUrl=${encodeURIComponent(returnUrl)}&Username=${userName
    || 'invalid'}&Password=${password || 'invalid'}&TenantUuid=${tenantUuid}`;

  return makeApiPostCall(Constants.LOGIN_ROUTE, cancelToken, payload, headers);
};

export const logOut = async () => makeApiGetCall(Constants.LOGOUT_ROUTE);

/** Tenants */
export const getTenant = async (cancelToken, tenantUuid) => makeApiGetCall(`${Constants.TENANTS_ROUTE}/${tenantUuid}`, cancelToken);

export const getTenantByAlias = async (cancelToken, tenantName) => {
  const encodedTenantName = encodeURIComponent(tenantName.replace(/\//g, '%2F'));
  const query = `${Constants.TENANT_ROUTE}/${encodedTenantName}`;
  return makeApiGetCall(query, cancelToken);
};

export const getTenants = async (
  cancelToken,
  searchText = '',
  filters = [],
  pageSize = Constants.PAGE_SIZE_DEFAULT,
  offset = 0,
) => {
  let filterQuery = filters
    .filter(filter => filter.active)
    .map(filterOption => `${filterOption.field} eq ${filterOption.value}`)
    .join(' or ');

  filterQuery = filterQuery ? ` and (${filterQuery})` : '';
  const trimmedSearch = searchText.trim().toLowerCase();
  const odataPage = odataPageQuery(pageSize, offset);
  const odataFilter = {
    $filter: `(contains(toLower(Name),'${trimmedSearch}') or aliases/any(t:contains(toLower(t),'${trimmedSearch}')))${filterQuery}`,
  };

  const queryParams = { ...odataFilter, ...odataPage, $orderby: 'Name' };

  return makeApiGetCall(Constants.TENANTS_ROUTE, cancelToken, queryParams);
};

export const postTenant = async (cancelToken, postBody) => {
  const headers = { 'Content-Type': 'application/json' };
  return makeApiPostCall(Constants.TENANTS_ROUTE, cancelToken, postBody, headers);
};

export const putTenant = async (cancelToken, postBody, tenantUuid) => {
  const headers = { 'Content-Type': 'application/json' };
  return makeApiPutCall(
    `${Constants.TENANTS_ROUTE}/${tenantUuid}`,
    cancelToken,
    postBody,
    headers,
  );
};

export const deleteTenant = async (cancelToken, tenantUuid) => {
  const route = `${Constants.TENANTS_ROUTE}/${tenantUuid}`;
  return makeApiDeleteCall(route, cancelToken);
};

/** Users */
export const getUser = async (cancelToken, userName) => {
  const route = `${Constants.USERS_ROUTE}/${encodeURIComponent(userName)}`;
  return makeApiGetCall(route, cancelToken);
};

export const getUsers = async (
  cancelToken,
  searchText = '',
  pageSize = Constants.PAGE_SIZE_DEFAULT,
  offset = 0,
) => {
  const splitSearch = searchText.trim().split(' ');
  const nameFilter = splitSearch
    .map(term => `contains(NormalizedUserName,'${term.toUpperCase()}')`)
    .join(' and ');
  const claimsFilter = splitSearch
    .map(term => `claims/any(t:contains(toLower(t/claimvalue),'${term.toLowerCase()}'))`)
    .join(' and ');
  const odataPage = odataPageQuery(pageSize, offset);
  const odataFilter = {
    $filter: `${nameFilter} or ${claimsFilter}`,
  };
  const queryParams = { ...odataFilter, ...odataPage };
  return makeApiGetCall(Constants.USERS_ROUTE, cancelToken, queryParams);
};

export const putUser = async (cancelToken, putBody, userName) => {
  const route = `${Constants.USERS_ROUTE}/${encodeURIComponent(userName)}`;
  const headers = { 'Content-Type': 'application/json' };
  return makeApiPutCall(route, cancelToken, putBody, headers);
};

export const putUserProducts = async (cancelToken, putBody, userName) => {
  const route = `${Constants.USERS_EXTERNAL_ROUTE}/${encodeURIComponent(userName)}/products`;
  const headers = { 'Content-Type': 'application/json' };
  return makeApiPutCall(route, cancelToken, putBody, headers);
};

export const deleteUser = async (cancelToken, userName) => {
  const route = `${Constants.USERS_ROUTE}/${userName}`;
  return makeApiDeleteCall(route, cancelToken);
};

/** Identity Providers */
export const getIdentityProviders = async (
  cancelToken,
  tenantUuid,
  searchText = '',
) => {
  const odataFilter = {
    $filter: `TenantUuids/any(t:t eq '${tenantUuid}') and (contains(Id,'${searchText}') or contains(Description,'${searchText}'))`,
  };
  const queryParams = { ...odataFilter };
  return makeApiGetCall(
    Constants.TENANT_IDENTITY_PROVIDERS_ROUTE,
    cancelToken,
    queryParams,
  );
};

export const postIdentityProvider = async (cancelToken, postBody) => {
  const headers = { 'Content-Type': 'application/json' };
  return makeApiPostCall(
    Constants.TENANT_IDENTITY_PROVIDERS_ROUTE,
    cancelToken,
    postBody,
    headers,
  );
};

export const putIdentityProvider = async (cancelToken, putBody, identityProviderId) => {
  const headers = { 'Content-Type': 'application/json' };
  return makeApiPutCall(
    `${Constants.TENANT_IDENTITY_PROVIDERS_ROUTE}/${identityProviderId}`,
    cancelToken,
    putBody,
    headers,
  );
};

export const getProviderByName = async (cancelToken, name, type) => makeApiGetCall(
  `${
    Constants.TENANT_IDENTITY_PROVIDERS_ROUTE
  }?$filter=ProviderType eq '${type}' and Name eq '${name}'`,
  cancelToken,
);

export const deleteIdentityProvider = async (cancelToken, identityProviderId) => {
  const route = `${Constants.TENANT_IDENTITY_PROVIDERS_ROUTE}/${identityProviderId}`;
  return makeApiDeleteCall(route, cancelToken);
};

/** Claims */
export const getCommonClaims = async cancelToken => makeApiGetCall(Constants.COMMON_CLAIMS, cancelToken);

/** Products */
export const getProduct = async (cancelToken, productId) => {
  const route = `${Constants.CLIENTS_ROUTE}/${encodeURIComponent(productId)}`;
  return makeApiGetCall(route, cancelToken);
};

export const getProducts = async (
  cancelToken,
  clientId = '',
  clientName = '',
  pageSize,
  offset = 0,
) => {
  const odataPage = pageSize
    ? odataPageQuery(pageSize, offset)
    : {};
  const odataFilter = {
    $filter: `contains(toLower(ClientId),'${clientId.trim().toLowerCase()}') or contains(toLower(ClientName),'${clientName.trim().toLowerCase()}')`,
  };

  const queryParams = { ...odataFilter, ...odataPage, $orderby: 'ClientName' };

  return makeApiGetCall(Constants.CLIENTS_ROUTE, cancelToken, queryParams);
};

export const getProductsById = async (
  cancelToken,
  clientId = '',
  clientName = '',
  pageSize,
  offset = 0,
) => {
  const odataPage = pageSize
    ? odataPageQuery(pageSize, offset)
    : {};
  const odataFilter = {
    $filter: `toLower(ClientId) eq '${clientId.trim().toLowerCase()}' or toLower(ClientName) eq '${clientName.trim().toLowerCase()}'`,
  };

  const queryParams = { ...odataFilter, ...odataPage, $orderby: 'ClientName' };

  return makeApiGetCall(Constants.CLIENTS_ROUTE, cancelToken, queryParams);
};

export const getProductNames = async cancelToken => {
  const queryParams = { $select: 'ClientId,ClientName', $orderby: 'ClientName' };

  return makeApiGetCall(Constants.CLIENTS_ROUTE, cancelToken, queryParams);
};

export const getUnassociatedProductTenants = async (
  cancelToken,
  clientId,
) => {
  const route = `${Constants.CLIENTS_ROUTE}/${clientId}/unassociatedtenants`;

  return makeApiGetCall(route, cancelToken);
};

export const postProduct = async (cancelToken, postBody) => {
  const headers = { 'Content-Type': 'application/json' };
  return makeApiPostCall(Constants.CLIENTS_ROUTE, cancelToken, postBody, headers);
};

export const putProduct = async (cancelToken, postBody, clientId) => {
  const headers = { 'Content-Type': 'application/json' };
  return makeApiPutCall(
    `${Constants.CLIENTS_ROUTE}/${clientId}`,
    cancelToken,
    postBody,
    headers,
  );
};

export const putProductSecret = async (cancelToken, patchBody, clientId, secretDescription) => {
  const headers = { 'Content-Type': 'application/json' };
  return makeApiPutCall(
    `${Constants.CLIENTS_ROUTE}/${clientId}/secrets/${encodeURIComponent(secretDescription)}`,
    cancelToken,
    patchBody,
    headers,
  );
};

export const postProductSecret = async (cancelToken, patchBody, clientId) => {
  const headers = { 'Content-Type': 'application/json' };
  return makeApiPostCall(
    `${Constants.CLIENTS_ROUTE}/${clientId}/secrets`,
    cancelToken,
    patchBody,
    headers,
  );
};

/** Metrics */
export const postLandingPageMetrics = async (cancelToken, postBody) => {
  const headers = { 'Content-Type': 'application/json' };
  return makeApiPostCall(
    `${Constants.METRICS_ROUTE}/landingPageInteraction`,
    cancelToken,
    postBody,
    headers,
  );
};

/** Well Known OpendId Configurations */
export const getWellKnownConfigurations = async cancelToken => {
  const route = Constants.ROUTE_WELL_KNOWN_CONFIGS;
  return makeApiGetCall(route, cancelToken);
};

/** Api Resources */
export const getApiResource = async (cancelToken, resourceName) => {
  const route = `${Constants.API_RESOURCES_ROUTE}/${encodeURIComponent(resourceName)}`;
  return makeApiGetCall(route, cancelToken);
};

export const postApiResource = async (cancelToken, apiResourceName, resource) => {
  const headers = { 'Content-Type': 'application/json' };
  return makeApiPostCall(
    Constants.API_RESOURCES_ROUTE,
    cancelToken,
    { Name: apiResourceName, ...resource },
    headers,
  );
};

export const deleteApiResource = async (cancelToken, resourceName) => {
  const route = `${Constants.API_RESOURCES_ROUTE}/${encodeURIComponent(resourceName)}`;
  return makeApiDeleteCall(route, cancelToken);
};

export const postApiResourceScope = async (cancelToken, resourceName, scope) => {
  const headers = { 'Content-Type': 'application/json' };
  return makeApiPostCall(
    `${Constants.API_RESOURCES_ROUTE}/${encodeURIComponent(resourceName)}/scopes`,
    cancelToken,
    { Name: scope },
    headers,
  );
};

export const deleteApiResourceScope = async (cancelToken, resourceName, scope) => {
  const route = `${Constants.API_RESOURCES_ROUTE}/${encodeURIComponent(resourceName)}/scopes/${scope}`;
  return makeApiDeleteCall(route, cancelToken);
};

export const putApiResourceClaims = async (cancelToken, resourceName, claims) => {
  const headers = { 'Content-Type': 'application/json' };
  return makeApiPutCall(
    `${Constants.API_RESOURCES_ROUTE}/${encodeURIComponent(resourceName)}/claims`,
    cancelToken,
    { UserClaims: claims },
    headers,
  );
};

export default loadUserDetails;
