import React, { Component } from 'react';
import { PropTypes } from 'prop-types';
import axios from 'axios';
import queryString from 'query-string';
import { loadLoginViewModel } from '../../../../api/apiCalls';
import AppLogin from '../AppLogin/AppLogin';
import IdentityProviders from '../IdentityProviders/IdentityProviders';
import * as Constants from '../../../../constants';
import { displayNetworkError } from '../../../Utilities/networkUtilities';
import { hasLoginCookie } from '../../../Utilities/accountUtilities';

export default class LoginForm extends Component {
  constructor() {
    super();
    this.state = {
      loginViewModel: undefined,
      loading: false,
    };
  }

  componentDidMount() {
    this.onLoadLoginViewModel();
  }

  componentWillUnmount() {
    if (this.signal) {
      this.signal.cancel(Constants.CANCELED_UNMOUNTING);
    }
  }

  onLoadLoginViewModel = async () => {
    const { queryValues } = this.props;

    // Cancel the previous request.
    if (this.signal) {
      this.signal.cancel(Constants.CANCELED_NEW_REQUEST);
    }

    const loadingTimeout = setTimeout(
      () => this.setState({ loading: true }),
      Constants.LoadingIconDelayMilliseconds,
    );

    // Save the new request for cancellation.
    this.signal = axios.CancelToken.source();

    try {
      const data = await loadLoginViewModel(this.signal.token, queryValues);
      clearTimeout(loadingTimeout);
      this.tryDefaultProvider(data);
      this.setState({
        loginViewModel: data,
        loading: false,
      });
    } catch (error) {
      clearTimeout(loadingTimeout);
      displayNetworkError(error);
      this.setState({ loading: false });
    }
  };

  tryDefaultProvider = loginViewModel => {
    const accessedByAuthorizedProcess = hasLoginCookie();

    if (
      loginViewModel
      && loginViewModel.externalProviders
      && loginViewModel.externalProviders.length === 1
      && accessedByAuthorizedProcess
    ) {
      // TODO: Use default external provider
    }
  };

  getTenantUuidFromReturnUrl = encodedUrl => {
    const returnUrl = queryString.parse(encodedUrl);
    let tenantUuid;
    if (typeof returnUrl.acr_values === 'string') {
      const tenantUuidIndex = returnUrl.acr_values.indexOf(':');
      tenantUuid = returnUrl.acr_values.substring(tenantUuidIndex + 1);
    }
    return tenantUuid;
  };

  render() {
    const { loginViewModel, loading } = this.state;
    const {
      acknowledgementNeeded, breakpoint, queryValues, tenantQuery,
    } = this.props;
    const hasVisibleProviders = loginViewModel
      && loginViewModel.visibleExternalProviders
      && loginViewModel.visibleExternalProviders.length > 0;

    const tenantUuid = tenantQuery.tenantUuid || this.getTenantUuidFromReturnUrl(queryValues.ReturnUrl);

    return (
      <div id="login-form">
        <AppLogin
          {...loginViewModel}
          breakpoint={breakpoint}
          acknowledgementNeeded={acknowledgementNeeded}
          tenantUuid={tenantUuid}
          loading={loading}
        />
        {hasVisibleProviders
          && (
          <IdentityProviders
            isSecondaryOption={loginViewModel.enableLocalLogin}
            externalProviders={loginViewModel.visibleExternalProviders}
            returnUrl={queryValues.ReturnUrl}
            tenantUuid={tenantUuid}
          />
          )}
      </div>
    );
  }
}

LoginForm.propTypes = {
  acknowledgementNeeded: PropTypes.bool,
  breakpoint: PropTypes.number,
  queryValues: PropTypes.shape({
    ReturnUrl: PropTypes.string,
    tenantuuid: PropTypes.string,
  }),
  tenantQuery: PropTypes.shape({
    tenantUuid: PropTypes.string,
  }),
};

LoginForm.defaultProps = {
  acknowledgementNeeded: false,
  breakpoint: Constants.MOBILE_BREAKPOINT,
  queryValues: {},
  tenantQuery: {},
};
