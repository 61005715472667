import translationEn from '../../translations/en.json';
import translationEs from '../../translations/es.json';
import translationDe from '../../translations/de.json';
import translationEo from '../../translations/eo.json';

const supportedTranslations = {
  en: translationEn,
  es: translationEs,
  de: translationDe,
  eo: translationEo, // Pseudo-Localization for untranslated string detection.
};

export default function getTranslatedMessages(
  translations = supportedTranslations,
) {
  // Detect primary language from different browser types
  // Chrome / Firefox
  if (navigator.languages) {
    for (const language of navigator.languages) {
      if (translations[language] !== undefined) {
        const translatedMessages = translations[language];
        return { language, translatedMessages };
      }
      // Split locales with a region code
      const languageWithoutRegionCode = language
        .toLowerCase()
        .split(/[_-]+/)[0];
      if (translations[languageWithoutRegionCode] !== undefined) {
        const translatedMessages = translations[languageWithoutRegionCode];
        return { language, translatedMessages };
      }
    }
  }
  // All browsers || IE <= 10 || default
  const language = navigator.language || navigator.userLanguage || 'en';

  // Split locales with a region code
  const languageWithoutRegionCode = language.toLowerCase().split(/[_-]+/)[0];

  // Try full locale, try locale without region code, fallback to 'en'
  const translatedMessages = translations[language] || translations[languageWithoutRegionCode];
  return { translatedMessages, language };
}
